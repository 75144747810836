/* Built In Imports */
import React from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */

import SliderCard from '@lb/components/Sliders/VisitTempleCards/SliderCard';
import SlideCardImage from '@lb/components/Sliders/VisitTempleCards/SliderCardImage';
import InnerSliderCard from "@lb/components/Sliders/VisitTempleCards/InnerSliderCard"
/* Styles */

/* Services */

const VisitBhairaviSlider = ({ section, region, lang, sectionId, pType }) => {
  // const [slideIndex, setSlideIndex] = useState(0);
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   arrows: false,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   vertical: true,
  //   verticalSwiping: true,
  //   appendDots: dots => (
  //     <Box
  //       style={{
  //         position: 'absolute',
  //         bottom: '50%',
  //         display: 'flex',
  //       }}
  //       flexDir="column"
  //       alignItems="center"
  //       top={{ base: '30px', md: '30px', lg: '50%' }}
  //       right={{ base: '16px', md: '16px', lg: '100%' }}
  //       left={{
  //         base: '90%',
  //         sm: '90%',
  //         md: '90%',
  //         lg: '70px',
  //       }}
  //       w="50px"
  //       maxW="60px"
  //     >
  //       <ul style={{ margin: '0px' }}> {dots} </ul>
  //     </Box>
  //   ),
  //   customPaging: i => (
  //     <div
  //       style={{
  //         width: '8px',
  //         height: '8px',
  //         color: 'transparent',
  //         borderRadius: '50%',
  //       }}
  //       className="dot"
  //     >
  //       {i + 1}
  //     </div>
  //   ),
  // };

  // const goToNextSlide = () => {
  //   // console.log('goToNextSlide', window.innerHeight);
  //   window &&
  //     window.scrollTo({
  //       top: window.innerHeight,
  //       behavior: 'smooth',
  //     });
  //   // console.log('goToNextSlide11');
  // };

  return (
    <>
      <Box
        w="100%"
        mx="auto"
        mb={{ base: '10px', md: '0' }}
        h="auto"
        transition="all 0.6s linear"
      >
        <Box className="SliderFullPage" pos="relative" h="100%">
          <Box
            w={{ base: 'auto', lg: 'auto', xl: '100px' }}
            height={{ base: 'auto', lg: 'auto', xl: '100px' }}
            position="absolute"
            top={{ base: '30px', lg: '30px', xl: 'calc(50% - 50px)' }}
            left={{
              base: 'calc(100% - 60px)',
              lg: 'calc(100% - 60px)',
              xl: '60px',
            }}
            zIndex={10}
            py="30px"
          >
            {/* <List
              style={{ margin: '0px' }}
              display="flex"
              justifyContent="space-between"
              flexDir="column"
              gap="10px"
            >
              <ListItem>
                <NextLink href="#visit-bhairavi-1" passHref>
                  <Link
                    onClick={() => {
                      goToNextSlide();
                    }}
                  >
                    <div
                      style={{
                        width: '8px',
                        height: '8px',
                        color: 'white',
                        margin: '10px',

                        borderRadius: '50%',
                      }}
                      className={
                        sectionId === 'visit-bhairavi-1'
                          ? 'visit-dot'
                          : 'visit-dot-no'
                      }
                    ></div>
                  </Link>
                </NextLink>
              </ListItem>
              <ListItem>
                <NextLink href="#visit-bhairavi-2" passHref>
                  <Link>
                    <div
                      style={{
                        width: '8px',
                        height: '8px',
                        color: 'white',
                        margin: '10px',

                        borderRadius: '50%',
                      }}
                      className={
                        sectionId === 'visit-bhairavi-2'
                          ? 'visit-dot'
                          : 'visit-dot-no'
                      }
                    ></div>
                  </Link>
                </NextLink>
              </ListItem>
              <ListItem>
                <NextLink href="#visit-bhairavi-3" passHref>
                  <Link>
                    <div
                      style={{
                        width: '8px',
                        height: '8px',
                        color: 'white',
                        margin: '10px',

                        borderRadius: '50%',
                      }}
                      className={
                        sectionId === 'visit-bhairavi-3'
                          ? 'visit-dot'
                          : 'visit-dot-no'
                      }
                    ></div>
                  </Link>
                </NextLink>
              </ListItem>
            </List> */}
          </Box>
          {/* <Slider {...settings}> */}
          {section?.sectionContent[0]?.tabContent?.map(slider => {
            if (sectionId === 'visit-bhairavi-1') {
              return (
                <SliderCard
                  slider={slider}
                  region={region}
                  key={nanoid()}
                  lang={lang}
                  sectionId={sectionId}
                  pType={pType}
                  section={section}
                />
              );
            }
            if (sectionId === 'visit-bhairavi-3') {
              return (
                <SlideCardImage
                  slider={slider}
                  region={region}
                  key={nanoid()}
                  lang={lang}
                  sectionId={sectionId}
                  pType={pType}
                  section={section}
                />
              );
            }
            return (
              <InnerSliderCard
                slider={slider}
                key={nanoid()}
                region={region}
                lang={lang}
                sectionId={sectionId}
                pType={pType}
                section={section}
              />
            );
          })}
          {/* </Slider> */}
        </Box>
        <style>
          {`
          .SliderFullPage .slick-dots li div{
            background:#9E2828;
          }
          .SliderFullPage .slick-dots .slick-active div::before{
            content:"";
            position:absolute;
            top:-5px;
            width:20px;
            left:-6px;
            height:20px;
            border-radius:50%;
            border: 3px solid #FFBC00;
          }
          .slick-dots .slick-active div{
            position:relative;
            box-sizing:border-box;
            background:#9E2828 !important;
          }
          .visit-dot, .visit-dot-no {
            position:relative;
            box-sizing:border-box;
            background:#9E2828 !important;
          }
          .visit-dot{
            position:relative;
            box-sizing:border-box;
            background:#9E2828 !important;
          }
          .visit-dot::before{
            content:"";
            position:absolute;
            top:-6px;
            width:20px;
            left:-6px;
            height:20px;
            border-radius:50%;
            border: 3px solid #FFBC00;
          }
          `}
        </style>
      </Box>
    </>
  );
};

export default VisitBhairaviSlider;
