/*External Imports */
import {
    Box,
    Button,
    Flex,
    Link,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';

/* Internal Components */
import ButtonIcon from '@lb/components/Icons/ButtonIcon';

const LunarCalendar = ({ sectionContent, pType, section }) => {
  return (
    <Box
      // h={{ base: 'auto', lg: '100vh' }}
      h={{
        base: pType == 'home' && section.viewH ? '100vh' : '812px',
        xl: pType == 'home' ? '100vh' : '615px',
      }}
      maxW={pType == 'home' ? '100%' : '1330px'}
      w="100%"
      bg="#570905"
      mx="auto"
      maxHeight="auto"
      bgImage={useBreakpointValue({
        base:
          sectionContent?.mobileImage?.url || sectionContent?.desktopImage?.url,
        lg: sectionContent?.desktopImage?.url,
      })}
      backgroundSize={{
        base: 'max(60%, 100%)',
        sm: 'max(40%, 60%)',
        md: '70%',
        lg: 'cover',
      }}
      backgroundRepeat="no-repeat"
      bgPos={{ base: 'top', lg: 'center' }}
    >
      {/* <LazyLoadImageComponent
        height={{ base: '455px', lg: '100%' }}
        objectFit="cover"
        w="100%"
        src={useBreakpointValue({
          base:
            sectionContent?.mobileImage?.url ||
            sectionContent?.desktopImage?.url,
          lg: sectionContent?.desktopImage?.url,
        })}
        alt=""
      /> */}
      <Box
        maxW="1170px"
        w="100%"
        h="100%"
        mx="auto"
        border="none"
        padding="none"
      >
        <Box
          display="flex"
          // margin={{ base: '56px auto' }}
          flexDir="column"
          alignItems={{ base: 'center', lg: 'flex-end' }}
          justifyContent={{ base: 'flex-end', lg: 'center' }}
          h="100%"
          px={{ base: '15px', md: '0px' }}
        >
          <Box
            textAlign="center"
            maxW="450px"
            py={{ base: 'max(28px, 56px)', lg: '0' }}
          >
            <Text
              color="title.light"
              fontSize={{ base: '26px', lg: '32px' }}
              fontFamily="FedraSerifAStdBook"
              lineHeight={{ base: '27.82px', lg: '34px' }}
              fontStyle="normal"
              fontWeight="350"
              textShadow="0px 0px 26px #000000"
            >
              {sectionContent?.subtext}
            </Text>
            <Text
              color="highlightText.color1"
              fontSize={{ base: 'h5', lg: 'h3' }}
              fontFamily="FedraSerifDisplay"
              lineHeight={{ base: '49.22px', lg: '68px' }}
              fontStyle="normal"
              fontWeight="600"
              textShadow="0px 0px 26px #000000"
              mb="34px"
            >
              {sectionContent?.title}
            </Text>
            <Text
              color="common.white"
              fontSize={{ base: 'body2', lg: 'body1' }}
              fontFamily="FedraSerifAStdBook"
              lineHeight={{ base: '25.6px', lg: '28px' }}
              fontStyle="normal"
              fontWeight="350"
              textAlign="center"
              mb="30px"
            >
              {sectionContent?.description}
            </Text>
            <Flex alignItems="center" justifyContent="center">
              <Button
                variant="primary"
                size="lg"
                rightIcon={<ButtonIcon />}
                textDecor="none"
              >
                <Link
                  href={sectionContent?.ctaLink}
                  textDecor="none"
                  _hover={{ textDecor: 'none' }}
                  _active={{ textDecor: 'none' }}
                  target="_blank"
                >
                  {sectionContent?.ctaLabel}
                </Link>
              </Button>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LunarCalendar;
