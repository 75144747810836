/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import VolunteerCircularImage from '@lb/components/Cards/VolunteerCircularImage';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders the Card Zigzag Group 2 Card component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} lang - Language for page
 * @param {string} region - Region for page
 * @returns {ReactElement} CardZigzagGroup Card component
 */

const CardZigzagGroup = ({ sectionContent, region, lang }) => {
  const style = sectionContent?.style;

  return (
    <>
      {sectionContent.cards.map((data, index) => {
        return (
          <Box key={nanoid()} display="flex" justifyContent="center">
            <VolunteerCircularImage
              data={data}
              region={region}
              lang={lang}
              index={index}
              key={nanoid()}
              style={style}
            />
          </Box>
        );
      })}
    </>
  );
};

export default CardZigzagGroup;
