/* Built In Imports */
import { useRouter } from 'next/router';

/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import { TiLocationOutline } from 'react-icons/ti';

/* Components */
/* Internal Imports */
import StructuredTextContent from '@lb/Common/StructuredTextContent';
import BackIcon from '@lb/components/Icons/BackIcon';
import ShareIcon from '@lb/components/Icons/ShareIcon';

/* Styles */

/**
 * Returns the Top Banner With Autoplay Video.
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Content from API
 * @param {string} region - Region of page
 * @param {string} language - Lang of page
 * @returns {ReactElement} TopBannerWithAutoplayVideo component
 */

const TopBannerWithAutoplayVideo = ({
  sectionContent,
  region,
  language,
  // pageName,
  locations,
}) => {
  const currentUrl = useRouter();
  const url = currentUrl.asPath;

  return (
    <Box
      maxW="1330px"
      w="100%"
      margin={{ base: '0 auto 40px', md: '0 auto 80px' }}
      width="100%"
    >
      <Box
        class="bkg"
        pos="relative"
        height="auto"
        _before={{
          content: '""',
          position: 'absolute',
          maxW: '236px',
          width: '20%',
          height: { base: '70px', md: '150px' },
          top: '100%',
          zIndex: 0,
          left: '0',
          background: 'bannerGradient.bannerOrange',
          transform: 'matrix(-1, 0, 0, 1, 0, 0)',
          mixBlendMode: 'normal',
          backgroundBlendMode: 'multiply',
        }}
        pb={{ base: '190px', md: '0' }}
      ><Box display="flex" position="relative">
          <video className="vid" autoPlay muted loop poster={sectionContent?.desktopImage?.url}>
            <source src={sectionContent?.autoplayVideoUrl} type="video/mp4" />
          </video>

          <Flex
            alignSelf="flex-start"
            position="absolute"
            top="30px"
            left="64px"
            right="45px"
            justifyContent="flex-start"
            display={{ base: 'none', md: 'flex' }}
            zIndex="22"
          >
            {sectionContent.showBackButton && (
              <BackIcon region={region} language={language} />
            )}
            {sectionContent.showShareButton && (
              <Box as="span" ml="15px">
                <ShareIcon url={url} background="#A9392D" />
              </Box>
            )}
          </Flex>

          <Box
            w={{ base: '100%', md: '80%' }}
            padding={{ base: '17px 16px 10px', md: '38px 59px 20px' }}
            maxW="600px"
            h={{ base: 'auto', md: "100%" }}
            margin={{ base: '40px 0px 0px', md: '20px auto 0px' }}
            bottom={{ base: '-190px', md: '0px' }}
            pos="absolute"
            background={{
              base: '#300303',
              md: 'linear-gradient(90deg, rgba(86, 8, 0, 0.8) 0%, rgba(86, 8, 0, 0.1) 100%)',

            }}
          >
            <Box
              w="100%"
              height="100%"
              maxW="652px"
              display="flex"
              justifyContent="flex-end"
              flexDir="column"
              pb={{ base: '18px', md: '72px' }}
            >
              {sectionContent?.title && (
                <Text
                  fontSize={{ base: '32px', md: 'h5', lg: 'h2' }}
                  as="h1"
                  p={0}
                  m={0}
                  lineHeight={{
                    base: '34px',
                    sm: '34px',
                    md: '60px',
                    lg: '75px',
                    xl: '85px',
                  }}
                  color="title.light"
                  maxW={{ base: '261px', md: '652px', lg: '652px' }}
                  fontFamily="'FedraSerifDisplay', serif"
                >
                  {sectionContent?.title}
                </Text>
              )}
              {render(sectionContent?.titleSubtext) &&
                render(sectionContent?.titleSubtext) !== '<p></p>' && (
                  <Box
                    id="event-description"
                    fontSize={{ base: '16px', md: '18px' }}
                    fontFamily="'FedraSansStd-book', sans-serif"
                    color="#333333"
                  >
                    {StructuredTextContent(sectionContent.titleSubtext)}
                  </Box>
                )}
              {render(sectionContent?.description) &&
                render(sectionContent?.description) !== '<p></p>' && (
                  <Box
                    id="event-description"
                    fontSize={{ base: '16px', md: '24px' }}
                    fontFamily="'FedraSansStd-book', sans-serif"
                    color="#FFCB66"
                  >
                    {StructuredTextContent(sectionContent.description)}
                  </Box>
                )}
              <style>
                {`
              .before:before{
                height:150px;
                bottom:-150px;
              }
                  #event-description p{
                    font-family: 'FedraSansStd-book', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 120%;
                    margin-top:10px;
                    color: #FFCB66;
                  }
                  @media (max-width:730px){
                    .before:before{
                        height:70px;
                        width:70px;
                        bottom:-70px;
                      }
                  }
                  @media (max-width:660px){
                    #event-description p{
                      font-family: 'FedraSansStd-book', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 120%;
                    margin-top:7px;
                    color: #FFCB66;
                    }
                
                  }
                  `}
              </style>
              {render(sectionContent?.date) &&
                render(sectionContent?.date) !== '<p></p>' && (
                  <Box
                    fontSize={{ base: 'body1', md: 'body1', lg: 'h6' }}
                    textAlign="left"
                    fontFamily={'"FedraSansStd-medium", sans-serif'}
                    fontWeight="500"
                    color="highlightText.color1"
                  >
                    {StructuredTextContent(sectionContent.date)}
                  </Box>
                )}

              {render(sectionContent?.closedMessage) &&
                render(sectionContent?.closedMessage) !== '<p></p>' && (
                  <Box
                    id="event-description"
                    fontSize={{ base: '16px', md: '18px' }}
                    fontFamily="'FedraSansStd-book', sans-serif"
                    color="#333333"
                  >
                    {StructuredTextContent(sectionContent.closedMessage)}
                  </Box>
                )}
              {render(sectionContent?.bottomText) &&
                render(sectionContent?.bottomText) !== '<p></p>' && (
                  <Box
                    id="event-description"
                    fontSize={{ base: '16px', md: '18px' }}
                    fontFamily="'FedraSansStd-book', sans-serif"
                    color="#333333"
                  >
                    {StructuredTextContent(sectionContent.bottomText)}
                  </Box>
                )}
              {locations?.length > 0 && (
                <Box mt={{ base: '15px', md: '12.42px' }}>
                  <Text
                    color="#EBDEDE"
                    fontFamily="'FedraSansStd-book', sans-serif"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize={{ base: '12px', md: 'body2' }}
                    lineHeight={{ base: '16.61px', md: '24.91px' }}
                    textTransform="uppercase"
                    fontFeatureSettings="'case' on"
                  >
                    <TiLocationOutline
                      style={{
                        color: '#DF6F3B',
                        display: 'inline',
                        fontSize: '20px',
                        marginTop: '-2px',
                      }}
                    />{' '}
                    {locations?.map((loc, index) => {
                      return (
                        index < 3 && (
                          <Box as="span" key={nanoid()}>
                            {loc.title}
                            {locations.length - 1 > index ? ',' : ''}&nbsp;
                          </Box>
                        )
                      );
                    })}
                    {locations?.length > 3 && (
                      <Box as="span">+ {locations?.length - 3} LOCATIONS</Box>
                    )}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Flex
          pos="absolute"
          top="0"
          right={0}
          display={{ base: 'flex', md: 'none' }}
          left="0"
          padding="15px"
          width="100%"
          h="auto"
          alignItems="center"
          justifyContent="space-between"
          color="#F5D5A9"
        >
          <Box fontSize="18px" cursor="pointer" pos="relative" zIndex={10}>
            <BackIcon background="none" region={region} language={language} />
          </Box>
          <Box fontSize="18px" cursor="pointer" pos="relative" zIndex={10}>
            <ShareIcon background={'none'} url={url} />
          </Box>
        </Flex>
      </Box>

      <style>
        {`
         
          .bkg {
            display: flex;
            align-items: stretch;
            justify-content: center;
            height:calc(100vh - 160px);
            min-height:600px;
            width: 100%;
            overflow: hidden;
            margin: 0 auto;
          }

          .vid {
            display: block;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height:calc(100vh - 160px);
            min-height:600px
          }
          @media(max-width:480px) {
            .bkg, .vid { 
              height:calc(100vh - 530px);
              min-height:320px;
            }
          }
         
          
          `}
      </style>
    </Box>
  );
};

export default TopBannerWithAutoplayVideo;
