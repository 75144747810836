/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Switch,
  Text,
} from '@chakra-ui/react';
import forEach from 'lodash/forEach';
import { nanoid } from 'nanoid';
import { FaTimes } from 'react-icons/fa';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';

/* Internal Imports */
/* Components */
import Search from '@lb/components/Icons/Search';

/* Styles */
/* Services */

/**
 * Returns LingaBhairaviFilter
 *
 * @param sectionContent
 */

const LingaBhairaviFilter = ({ sectionContent, section, pageData }) => {
  const inputRef = useRef('null');
  const router = useRouter();
  const [isOnline, setIsOnline] = useState(router.query.online);
  const [isOpen, setIsOpen] = useState(false);
  const [isCatOpen, setCatIsOpen] = useState(false);
  const [isSearchListOpen, setIsSearchListOpen] = useState(false);
  const [isCategory, setIsCategory] = useState(
    router.query.category || 'All Rituals'
  );
  // const { setSearch } = useFilterContext();
  const [locName, setLocation] = useState(
    router.query?.location || 'All Locations'
  );
  const [searchWord, setSearchWord] = useState(router.query?.search || '');
  const [searchCursor, setSearchCursor] = useState(-1);

  const RightIcon = () => {
    return (
      <>
        <Box
          display="inline-block"
          rounded="full"
          background="#B7987E"
          padding="5px"
          h="auto"
          fontSize="10px"
          w="auto"
        >
          <FaTimes style={{ color: '#fff' }} />
        </Box>
      </>
    );
  };

  const setFilter = (type, value) => {
    if (type === 'online') {
      setIsOnline(value);
    } else if (type === 'location') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'location_selection',
        location: value,
      });
    } else if (type === 'category') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'offerings_type',
        location: value,
      });
    }

    let queryParam = { ...router.query };
    delete queryParam.region;
    delete queryParam.lang;
    queryParam[type] = value;

    // {
    //   category: type === 'category' ? value : router.query.category,
    //   location: type === 'location' ? value : router.query.location,
    //   search: type === 'search' ? value : router.query.search,
    //   online: type === 'online' ? value : router.query.online,
    // }
    router.push(
      {
        pathname: router.asPath.split('?')[0],
        query: { ...queryParam },
      },
      undefined,
      { scroll: false }
    );
  };
  let allSearchList = [];

  const handleKeyUp = e => {
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && searchCursor > 0) {
      setSearchCursor(searchCursor - 1);
    } else if (e.keyCode === 40 && searchCursor < 8) {
      setSearchCursor(searchCursor + 1);
    } else if (e.keyCode === 13) {
      if (searchCursor === -1) {
        setSearchWord(searchWord);
        setIsSearchListOpen(false);
        setFilter('search', searchWord);
      } else {
        let filterResult = allSearchList?.filter(item => {
          return item.toLowerCase().includes(searchWord);
        });
        setSearchWord(filterResult[searchCursor]);
        setIsSearchListOpen(false);
        setFilter('search', filterResult[searchCursor]);
      }
    }
  };

  forEach(section.sectionContent, function (sec) {
    if (
      sec?._modelApiKey === 'linga_bhairavi_card' &&
      sec.title !== 'Popular'
    ) {
      allSearchList.push(sec.title.trim().toLowerCase());
      forEach(sec?.events, function (event) {
        allSearchList.push(event.title.trim().toLowerCase());
        forEach(event.tags?.split(','), function (tag) {
          allSearchList.push(tag.trim().toLowerCase());
        });

        forEach(pageData?.arpanam?.rituals, ritual => {
          if (ritual.arpanamId === event.arpanamId) {
            if (!pageData?.overrideRegistrationLogic) {
              forEach(ritual?.locations, function (loc) {
                allSearchList.push(loc.trim().toLowerCase());
              });
            }
          }
        });
      });
    }
  });

  useEffect(() => {
    let locList = sectionContent?.locationList?.filter(loc => {
      return loc.title.toLowerCase() === router.query?.location?.toLowerCase();
    });
    if (locList?.length) {
      setLocation(locList[0]?.title);
    } else {
      setLocation('All Locations');
    }
  }, []);

  allSearchList = allSearchList
    .filter((value, index, array) => array.indexOf(value) === index)
    .sort();

  return (
    <Box
      p={{ base: '20px 16px', md: '20px 5%' }}
      maxW="1330px"
      w="100%"
      m="0 auto"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
    >
      <Box
        width="100%"
        height="auto"
        padding={{ base: '10px 0 20px 0', md: '20px 0' }}
      >
        <Flex
          w="100%"
          h="auto"
          flexWrap="wrap"
          flexDir={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          style={{ gap: '10px' }}
        >
          <Box w={{ base: '100%', md: '100%', lg: '55%' }}>
            <Flex
              alignItems="center"
              justifyContent="center"
              maxH={{ base: '42px', md: '46px' }}
              background={sectionContent?.searchFilterVisibility && '#ffffff'}
              border={
                sectionContent?.searchFilterVisibility && '1px solid #B7987E'
              }
              borderRadius="30px"
              textAlign="center"
              w={{ base: '100%', lg: '552px' }}
              padding={
                sectionContent?.searchFilterVisibility ? '14px 20px' : '0'
              }
              color="#B7987E"
            >
              {sectionContent?.searchFilterVisibility && (
                <InputGroup
                  color="#B7987E"
                  w="100%"
                  display="flex"
                  variant="outline"
                  alignItems="center"
                >
                  <InputLeftElement
                    pos="static"
                    mt="3px"
                    cursor="pointer"
                    color="#B7987E"
                    ml={{ base: '0', md: '10px' }}
                    fontSize="1.2em"
                  >
                    <Search />
                  </InputLeftElement>
                  <Input
                    value={searchWord}
                    onKeyUp={e => {
                      handleKeyUp(e);
                    }}
                    // name="search"
                    ml={{ base: '5px', md: '8px' }}
                    p="0"
                    ref={inputRef}
                    id="search"
                    bg="transparent"
                    fontSize={{ base: 'body2', md: 'body1' }}
                    lineHeight={{ base: '20px', md: '22px' }}
                    autoComplete="off"
                    _focus={{
                      border: 'none',
                      outline: 'none',
                      bg: 'transparent',
                    }}
                    _hover={{
                      border: 'none',
                      outline: 'none',
                      bg: 'transparent',
                    }}
                    fontWeight={350}
                    color="#DB3030"
                    _placeholder={{ color: '#B7987E' }}
                    type="text"
                    placeholder="Search Rituals"
                    border="none"
                    outline="none"
                    onChange={event => {
                      // setSearch(event.target.value);
                      setSearchWord(event.target.value);
                      setIsSearchListOpen(true);
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        // setSearch(e.target.value);
                        setSearchWord(e.target.value);
                        setIsSearchListOpen(false);
                      }
                    }}
                    variant="unstyled"
                    textTransform="capitalize"
                  />
                  {searchWord?.length > 0 && (
                    <InputRightElement
                      cursor="pointer"
                      pos="static"
                      onClick={e => {
                        // setSearch('');
                        setSearchWord('');
                        setFilter('search', '');
                      }}
                    >
                      <RightIcon />
                    </InputRightElement>
                  )}
                  {searchWord?.length > 0 &&
                    isSearchListOpen &&
                    allSearchList?.filter(item => {
                      return item.toLowerCase().includes(searchWord);
                    }).length > 0 && (
                      <Flex
                        _before={{ content: "''", height: '17px' }}
                        _after={{ content: "''", height: '17px' }}
                        display={isSearchListOpen ? 'block' : 'none'}
                        _hover={{ display: 'block' }}
                        className="options"
                        alignItems="flex-start"
                        alignContent="flex-start"
                        onBlur={() => {
                          setIsSearchListOpen(false);
                        }}
                        width="113%"
                        maxWidth="550px"
                        onClick={() => setIsSearchListOpen(false)}
                        position="absolute"
                        top={{ base: '45px', md: '47px' }}
                        border="1px #EDD1C2 solid"
                        zIndex="100"
                        left="-20px"
                        borderRadius="14px"
                        minW={{ base: '192px', md: '227px' }}
                        flexDir="column"
                        bg="#ffffff"
                        mt="8px"
                        p="15px 23px 12px 23px"
                      >
                        {allSearchList
                          ?.filter(item => {
                            return item.toLowerCase().includes(searchWord);
                          })
                          ?.map((item, index) => {
                            return (
                              index < 10 && (
                                <Box
                                  padding="8px 0px"
                                  cursor="pointer"
                                  key={nanoid()}
                                >
                                  <Text
                                    color={
                                      searchCursor === index
                                        ? '#A33A2C'
                                        : '#B7987E'
                                    }
                                    fontWeight={
                                      searchCursor === index ? '400' : '350'
                                    }
                                    fontFamily="FedraSansStd-book"
                                    textAlign="left"
                                    textTransform="capitalize"
                                    _hover={{
                                      color: '#A33A2C',
                                      fontWeight: '400',
                                    }}
                                    onClick={event => {
                                      // setSearch(item);
                                      setSearchWord(item);
                                      setFilter('search', item);
                                      inputRef.current = item;
                                    }}
                                  >
                                    {item}
                                  </Text>
                                </Box>
                              )
                            );
                          })}
                      </Flex>
                    )}
                </InputGroup>
              )}
            </Flex>
          </Box>
          <Flex
            w={{ base: '100%', md: '100%', lg: '40%' }}
            flexWrap="wrap"
            // w={{}}
            justifyContent={{ base: 'flex-start', md: 'flex-end' }}
            gridGap={{ base: '5px', sm: '10px', md: '10px' }}
          >
            {sectionContent?.ritualCategoryVisibility && (
              <Button
                flex="1"
                bg="#ffffff"
                value={isCategory}
                border="1px solid #B7987E"
                color="#B7987E"
                w={{ base: '164px', md: '227px' }}
                h={{ base: '36px', md: '46px' }}
                rounded="full"
                fontWeight={350}
                display="inline-flex"
                justifyContent="space-between"
                _hover={{ border: '1px solid #B7987E' }}
                _focus={{ border: '1px solid #B7987E' }}
                _active={{ border: '1px solid #B7987E' }}
                rightIcon={isCatOpen ? <TbChevronUp /> : <TbChevronDown />}
                onClick={() => setCatIsOpen(!isCatOpen)}
                onBlur={() => setCatIsOpen(false)}
                className="selectButton"
                textTransform="capitalize"
                fontFamily="FedraSerifAStdBook"
              >
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  // color="#A33A2C"
                >
                  {isCategory.replace(/-/g, ' ')}
                </Text>
                {isCatOpen && (
                  <Flex
                    _before={{ content: "''", height: '17px' }}
                    _after={{ content: "''", height: '17px' }}
                    display={isCatOpen ? 'block' : 'none'}
                    _hover={{ display: 'block' }}
                    className="options"
                    alignItems="flex-start"
                    alignContent="flex-start"
                    // onMouseLeave={() => {
                    //   setCatIsOpen(false);
                    //   setIsCategory('All Rituals');
                    // }}
                    onClick={() => setCatIsOpen(false)}
                    position="absolute"
                    top={{ base: '45px', md: '47px' }}
                    border="1px solid #EDD1C2"
                    zIndex="100"
                    left="0"
                    borderRadius="14px"
                    minW={{ base: '192px', md: '227px' }}
                    flexDir="column"
                    bg="#ffffff"
                    mt={{ base: '0', md: '8px' }}
                    w="auto"
                    p="17px"
                  >
                    <Box
                      padding="8px 0px"
                      cursor="pointer"
                      onClick={() => {
                        setIsCategory('');
                        setFilter('category', '');
                      }}
                    >
                      <Text
                        color="#B7987E"
                        fontFamily="FedraSansStd-book"
                        textAlign="left"
                        _hover={{ color: '#A33A2C', fontWeight: '400' }}
                      >
                        All Rituals
                      </Text>
                    </Box>
                    {sectionContent?.ritualCategoryList?.map((cat, i) => {
                      return (
                        <Box
                          padding="8px 0px"
                          cursor="pointer"
                          key={nanoid()}
                          selected={cat.urlAlias === router.query.category}
                          value={cat.urlAlias}
                        >
                          <Text
                            color="#B7987E"
                            fontFamily="FedraSansStd-book"
                            textAlign="left"
                            _hover={{ color: '#A33A2C', fontWeight: '400' }}
                            // onMouseEnter={() => setIsCategory(cat.title)}
                            onClick={() => {
                              setIsCategory(cat.title);
                              setFilter('category', cat.urlAlias);
                            }}
                          >
                            {cat.title}
                          </Text>
                        </Box>
                      );
                    })}
                  </Flex>
                )}
              </Button>
            )}

            {sectionContent?.locationFilterVisibility && (
              <Button
                flex="1"
                bg="#ffffff"
                border="1px solid #B7987E"
                color="#B7987E"
                w="50%"
                maxW={{ base: 'auto', xl: '227px' }}
                minW={{ base: '164px', md: '227px' }}
                h={{ base: '36px', md: '46px' }}
                rounded="full"
                display="inline-flex"
                fontWeight={350}
                justifyContent="space-between"
                _hover={{ border: '1px solid #B7987E' }}
                _focus={{ border: '1px solid #B7987E' }}
                _active={{ border: '1px solid #B7987E' }}
                rightIcon={isOpen ? <TbChevronUp /> : <TbChevronDown />}
                onClick={() => setIsOpen(!isOpen)}
                // onMouseEnter={() => setIsOpen(true)}
                onBlur={() => setTimeout(() => setIsOpen(false), 1000)}
                className="selectButton"
                textTransform="capitalize"
                fontFamily="FedraSerifAStdBook"
              >
                {' '}
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {locName}
                </Text>
                {isOpen && sectionContent?.locationList?.length > 0 && (
                  <Flex
                    _before={{ content: "''", height: '17px' }}
                    _after={{ content: "''", height: '17px' }}
                    display={isOpen ? 'block' : 'none'}
                    _hover={{ display: 'block' }}
                    className="options"
                    alignItems="flex-start"
                    alignContent="flex-start"
                    // onMouseLeave={() => {
                    //   setIsOpen(false);
                    //   setLocation(router.query?.location || 'All Locations');
                    // }}
                    onClick={() => setIsOpen(!isOpen)}
                    position="absolute"
                    top={{ base: '45px', md: '47px' }}
                    border="1px solid #EDD1C2"
                    zIndex="100"
                    left="0"
                    borderRadius="14px"
                    minW={{ base: '192px', md: '227px' }}
                    flexDir="column"
                    bg="#ffffff"
                    mt={{ base: '0', md: '8px' }}
                    w="auto"
                    p="17px"
                  >
                    <Box
                      padding="8px 0px"
                      cursor="pointer"
                      onClick={() => {
                        setLocation('');
                        setFilter('location', '');
                      }}
                    >
                      <Text
                        color="#B7987E"
                        fontFamily="FedraSansStd-book"
                        textAlign="left"
                        _hover={{ color: '#A33A2C', fontWeight: '400' }}
                      >
                        All Locations
                      </Text>
                    </Box>
                    {sectionContent?.locationList?.map((loc, index) => {
                      return (
                        <Box padding="8px 0px" key={nanoid()} cursor="pointer">
                          <Text
                            color="#B7987E"
                            fontFamily="FedraSansStd-book"
                            textAlign="left"
                            _hover={{ color: '#A33A2C', fontWeight: '400' }}
                            // onMouseEnter={event => setLocation(loc.title)}
                            onClick={event => {
                              setLocation(loc.title);
                              setFilter('location', loc.title?.toLowerCase());
                            }}
                          >
                            {loc.title || loc.name}
                          </Text>
                        </Box>
                      );
                    })}
                  </Flex>
                )}
              </Button>
            )}
            {/* {sectionContent?.locationFilterVisibility && (
              <Select
                color="#B7987E"
                border="1px solid #B7987E"
                outline="1px solid #B7987E"
                bg="#ffffff"
                width={{ base: '47%', md: '227px' }}
                rounded="full"
                _hover={{ border: '1px solid #B7987E' }}
                _focus={{ border: '1px solid #B7987E' }}
                onChange={event => {
                  setFilter('location', event.target.value);
                }}
              >
                <option key="0" value="">
                  All Locations
                </option>
                {sectionContent?.locationList?.map((loc, i) => {
                  return (
                    <option
                      key={i}
                      selected={
                        loc?.title?.toLowerCase() ===
                        router.query?.location?.toLowerCase()
                      }
                    >
                      {loc.title}
                    </option>
                  );
                })}
              </Select>
            )} */}
          </Flex>
        </Flex>
      </Box>
      <Flex
        justifyContent="flex-end"
        alignItems="flex-end"
        padding="7px 0 0 0"
        style={{ gap: '16px' }}
        position="relative"
        zIndex="2"
      >
        {sectionContent?.showOnlineEventToggle && (
          <Flex
            textAlign="right"
            float="right"
            alignItems="center"
            position={{ base: 'static', lg: 'absolute' }}
            bottom="-50px"
          >
            <Flex alignItems="center" justifyContent="center">
              <FormLabel
                htmlFor="AvailEvents"
                display="inline"
                color="title.dark"
                textTransform="uppercase"
                fontSize={{ base: 'body3', md: 'body2' }}
                lineHeight={{ base: '20px', md: '23px' }}
                fontFamily="FedraSansStd-book, sans-serif"
                mb={{ base: '5px', sm: '6px' }}
                marginInlineEnd={{ base: 2, sm: 3 }}
              >
                Show events available online
              </FormLabel>
              <Switch
                onChange={event => {
                  setFilter('online', event.target.checked ? 'yes' : 'no');
                }}
                // colorScheme="#B7987E"
                // sx={{
                //   '--maroon': '#A9392D',
                //   'span.chakra-switch__track:not([data-checked])': {
                //     backgroundColor: 'common.brown',
                //   },
                //   'span.chakra-switch__track:[data-checked]': {
                //     backgroundColor: 'red',
                //   },
                // }}
                colorScheme="red"
                // bg="#A9392D"
                // p="5px"
                id="AvailEvents"
                // name="AvailRituals"
                display="inline"
                isChecked={isOnline === 'yes'}
              />
            </Flex>
          </Flex>
        )}

        {sectionContent?.showOnlineRitualToggle && (
          <Flex
            textAlign="right"
            float="right"
            alignItems="flex-start"
            position={{ base: 'static', lg: 'absolute' }}
            bottom="-58px"
          >
            <Flex alignItems="center" justifyContent="center">
              <FormLabel
                htmlFor="AvailRituals"
                display="inline"
                color="title.dark"
                textTransform="uppercase"
                fontSize={{ base: 'body3', md: 'body2' }}
                lineHeight={{ base: '20px', md: '23px' }}
                fontFamily="FedraSansStd-book, sans-serif"
                mb={{ base: '5px', sm: '6px' }}
                marginInlineEnd={{ base: 2, sm: 3 }}
              >
                Show rituals available online
              </FormLabel>
              <Switch
                // sx={{
                //   '--maroon': '#A9392D',
                //   'span.chakra-switch__track:not([data-checked])': {
                //     backgroundColor: 'common.brown',
                //   },
                //   'span.chakra-switch__track:[data-checked]': {
                //     backgroundColor: 'red',
                //   },
                // }}
                onChange={event => {
                  setFilter('online', event.target.checked ? 'yes' : 'no');
                }}
                colorScheme="red"
                display="inline"
                id="AvailRituals"
                isChecked={isOnline === 'yes'}
                // p="5px"
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default LingaBhairaviFilter;
