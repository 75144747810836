/* Built In Imports */
/* External Imports */
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    chakra,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import SectionTitle from '@lb/components/Headings/SectionTitle';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the Faq component.
 *
 * @param faqSection.faqSection
 * @param {Array} faqSection - Main FAQ data.
 * @param faqSection.region
 * @param faqSection.lang
 * @param faqSection.bgColor
 * @param faqSection.color
 * @returns {ReactElement} FaqSection component.
 */
const FaqSection = ({ faqSection, region, lang, bgColor, color }) => {
  return (
    <>
      <SectionTitle titleObj={faqSection} />
      <Box
        fontFamily="FedraSansStd-book"
        fontSize="1.1rem"
        lineHeight="1.61"
        width="100%"
        m="0 auto"
        mt="0"
        mb="10px"
        px="15px"
        id={faqSection?.id}
        className="faqSection"
        maxW="698px"
      >
        <Accordion
          key={nanoid()}
          allowMultiple
          m={{ base: '0 auto' }}
          width={{ base: '100%' }}
        >
          <AccordionItem border="none">
            <AccordionButton
              bgColor={'none'}
              borderRadius="0"
              mb="2px"
              fontFamily="FedraSansStd-medium"
              fontSize="20px"
              borderBottom="1.5px solid #65000F"
              padding="10px 13px 10px 13px"
              color={color || 'white'}
              // _hover={{ bgColor: bgColor || '#424786', color: color || 'white' }}
              _focus={{ shadow: 'transparent' }}
              minHeight="55px"
              lineHeight="1.5"
            >
              <chakra.div flex="1" textAlign="left" color="#65000F">
                {faqSection.title}
              </chakra.div>
              <AccordionIcon color="#65000F" />
            </AccordionButton>
            <AccordionPanel pb={1}>
              {faqSection?.faqs?.length
                ? faqSection.faqs.map(faq => {
                    return (
                      <FaqChildren
                        key={nanoid()}
                        faq={faq}
                        region={region}
                        lang={lang}
                      />
                    );
                  })
                : null}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <style>
          {`
        .commanFaqs {
          margin-top:0;
        }
        .commanFaqs div > div {
          margin-top:0;
        }
        
          .commanFaqs p {
            padding-bottom:25px;
          }
          .commanFaqs ol, .commanFaqs ul  {
            margin-left:20px;
          }   
        `}
        </style>
        <style global jsx>
          {`
            .faq a {
              color: #cf4520;
            }
            .faq span.setting:after {
              font-family: fontawesome;
              font-size: 1.6rem;
              content: '\f013';
              color: #fff;
              background: rgba(31, 41, 55, var(--tw-bg-opacity));
              border-radius: 18px;
              padding: 7px 8px 6px;
              --tw-bg-opacity: 1;
              vertical-align: middle;
            }
            .faq ul {
              padding-left: 1.5em;
            }
            .faq p {
              padding-bottom: 20px;
            }
          `}
        </style>
      </Box>
    </>
  );
};

const FaqChildren = ({ faq, region, lang }) => {
  return (
    <AccordionItem
      key={nanoid(4)}
      borderBottomWidth="1px"
      borderBottomColor="gray.400"
      borderTop="none"
      lineHeight="1.61"
      className="faqSectionItem"
    >
      <AccordionButton
        pb="0.8rem"
        fontFamily="FedraSansStd-medium"
        fontSize="1.1rem"
        borderTop="none"
        color="#fff"
        _hover={{ background: 'none' }}
        _focus={{ shadow: 'transparent' }}
        w={{ base: '105%', md: '653px' }}
      >
        <chakra.div
          w="100%"
          marginLeft={{ base: '-20px', md: '-20px' }}
          flex="1"
          textAlign="left"
          key={nanoid(4)}
          color="#121212"
        >
          {faq.question}
        </chakra.div>
        <AccordionIcon color="#65000F" />
      </AccordionButton>
      <AccordionPanel
        pb={2}
        fontSize="1.1rem"
        fontFamily="FedraSansStd-book"
        lineHeight="1.61"
      >
        <Box
          display="block"
          width="100%"
          lineHeight="1.61"
          fontSize={{ sm: '16px', lg: '18px' }}
          color="#28231e"
        >
          <Box className="commanFaqs">
            <StructuredTextParser
              str={render(faq.answer)}
              region={region}
              lang={lang}
              color="#49423B"
            />
          </Box>
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default FaqSection;
