/* Built In Imports */
import { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

const FilterProvider = ({ children }) => {
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState('');
  const [search, setSearch] = useState('');
  const [online, setOnline] = useState(false);
  const [blocksVis, setBlocksVis] = useState(0);
  const [showOnlineRitualToggle, setOnlineRitualToggle] = useState(false);
  
  const toggleOnlineRitualToggle = e => {
    e.preventDefault();
    setOnlineRitualToggle(prev => !prev);
  };

  return (
    <FilterContext.Provider
      value={{
        showOnlineRitualToggle,
        toggleOnlineRitualToggle,
        location,
        setLocation,
        category,
        setCategory,
        search,
        setSearch,
        online,
        setOnline,
        blocksVis,
        setBlocksVis
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};

export { FilterContext, FilterProvider };

