/* Built In Imports */
import React from 'react';

/* External Imports */
import { Box, List } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import PromotionalBanner from '@lb/components/Banners/PromotionalBanner';
/* Styles */
/* Services */

const PromotionalBannerSlider = ({
  sectionContent,
  region,
  language,
  section,
  pType,
}) => {
  const settingsSmall = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: false,
    lazyLoad: true,
    slidesToShow: 1,
    appendDots: dots => (
      <Box
        className="something"
        // pl="12%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        alignContent="center"
      >
        <List
          style={{ margin: '0px' }}
          className="dots"
          margin="-11px"
          height="auto"
          minH="30px"
          display="flex"
          alignItems="flex-start"
          justifyContent="start"
        >
          {dots}
        </List>
      </Box>
    ),

    slidesToScroll: 1,

    customPaging: () => (
      <Box
        bg="#9E2828"
        w="16px"
        rounded="full"
        height="16px"
        marginTop="-40px"
      ></Box>
    ),
  };
  //   console.log(sectionContent, region, language);
  return (
    <>
      <Box
        mx="auto"
        className="PromoteSlider"
        position="relative"
        w="100%"
        h={pType == 'home' && section.viewH ? '100vh' : 'auto'}
        maxW={pType == 'home' ? '100%' : '1330px'}
      >
        <Box
          maxH="auto"
          h={{
            base: pType == 'home' && section.viewH ? '100vh' : 'auto',
            // md: '750px',
            md: 'auto',
          }}
        >
          <Slider {...settingsSmall}>
            {sectionContent?.slides?.map(slide => {
              return (
                <PromotionalBanner
                  sectionContent={slide}
                  key={nanoid()}
                  mb={{ base: '35px', md: '0' }}
                  region={region}
                  language={language}
                  pType={pType}
                  section={section}
                />
              );
            })}
          </Slider>
        </Box>
        <style>
          {`
            .PromoteSlider .slick-dots li.slick-active div {
                position: relative;
              }
              .PromoteSlider .slick-dots li.slick-active div::before {
                content: '';
                position: absolute;
                top: -7px;
                left: -7px;
                bottom:60px;
                border: 3px solid #ffbc00;
                width: 30px;
                height: 30px;
                background: transparent;
                transform: rotate(180deg);
                border-radius: 100%;
              }
            `}
        </style>
      </Box>
    </>
  );
};

export default PromotionalBannerSlider;
