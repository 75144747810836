/* Built in Imports */
import NextLink from 'next/link';
import React from 'react';

/* External Imports */
import {
  Box,
  Button,
  Link,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMultiStyleConfig,
  useTab,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import BlogCard from '@lb/components/Blogs/BlogCard';
import { refineUrlDomain } from '@lb/components/Utility/utils';

/**
 *
 * @param {object} sectionContent
 * @returns
 */

const LingaBhairaviBlogHomeFacadePlaceholder = ({
  sectionContent,
  align,
  border,
  mt,
  region,
  lang,
  ...props
}) => {
  const tabColor = {
    default: '#FFCB66',
    'tree-decoration': '#BC4034',
    'triangle-decoration': '#BC4034',
  };
  /**
   *
   */
  // eslint-disable-next-line react/display-name
  const CustomTab = React.forwardRef((props, ref, bordeColo) => {
    // 1. Reuse the `useTab` hook
    const tabProps = useTab({ ...props, ref, bordeColo });
    const isSelected = !!tabProps['aria-selected'];

    // 2. Hook into the Tabs `size`, `variant`, props
    const styles = useMultiStyleConfig('Tabs', tabProps);

    return (
      <Button
        __css={styles.tab}
        {...tabProps}
        pos="relative"
        display="flex"
        justifyContent="center"
      >
        <Box mb={{ base: '7px',md: '8px',  }} w="max-content" textAlign="center">
          {tabProps.children}
        </Box>
        <Box
          as="span"
          // mr="2"
          pos="absolute"
          bottom="-4px"
          height="10px"
          width={sectionContent?.blockStyle !== 'default' ? '54px' : '150px'}
          // left={
          //   sectionContent?.blogCardStyle !== 'with_image'
          //     ? 'calc(50% - 27px)'
          //     : 'calc(50% - 105px)'
          // }
          background={
            isSelected ? tabColor[sectionContent?.blockStyle] : 'transparent'
          }
        ></Box>
      </Button>
    );
  });

  return (
    <Box
      maxW={1330}
      mx="auto"
      width="100%"
      height={'auto'}
      mt={mt || '130px'}
      bg={{ base: 'none', md: 'auto' }}
      pt={{
        base: sectionContent.blockStyle !== 'default' ? '60px' : '0',
        md: sectionContent.blockStyle !== 'default' ? '60px' : '0',
        xl: '0',
      }}
      background={{
        base:
          sectionContent.blockStyle !== 'default'
            ? 'linear-gradient(180deg, #D4C3A2 0%, rgba(217, 217, 217, 0) 60.56%)'
            : 'transparent',

        md:
          sectionContent.blockStyle !== 'default'
            ? 'linear-gradient(180deg, #D4C3A2 0%, rgba(217, 217, 217, 0) 60.56%)'
            : 'transparent',
        lg:
          sectionContent.blockStyle !== 'default'
            ? 'linear-gradient(180deg, #D4C3A2 0%, rgba(217, 217, 217, 0) 60.56%)'
            : 'transparent',
        xl: 'transparent',
      }}
    
      backgroundRepeat="no-repeat"
      {...props}
    >
      <Box w="100%">
        <Tabs
          size="lg"
          variant="line"
          align={sectionContent?.blockStyle !== 'default' ? 'end' : 'start'}
          colorScheme="primary.main"
        >
          <Box w="100%" pl="10px">
            <TabList
              px={{ base: '18px', md: '18px', lg: '60px' }}
              border={border || 'none'}
              className="browsebyTopic"
              // mb="13px"
              maxW={{ base: 'fit-content', md: '100%' }}
              overflowX={{ base: 'auto', md: 'hidden' }}
              overflowY="hidden"
              justifyContent={{
                base: 'flex-start',
                md:
                  sectionContent?.blockStyle !== 'default'
                    ? 'flex-end'
                    : 'flex-start',
              }}
            >
              {sectionContent?.tabs.map((tab) => (
                <CustomTab
                  // minW="169px"
                  w={
                    sectionContent?.blockStyle !== 'default' ? 'auto' : '150px'
                  }
                  key={nanoid()}
                  fontSize="20px"
                  lineHeight="25px"
                  p="10px 0"
                  mr="40px"
                  color={
                    sectionContent?.blockStyle === 'default'
                      ? 'bodyText.color3'
                      : 'bodyText.color1'
                  }
                  _active={{
                    background: 'transparent',
                    // borderBottom:
                    //   sectionContent.blogCardStyle === 'with_image'
                    //     ? '4px solid #FFCB66'
                    //     : '4px solid #BC4034',
                    color:
                      sectionContent?.blockStyle === 'default'
                        ? 'highlightText.color2'
                        : '#BC4034',
                  }}
                  _selected={{
                    // borderBottom:
                    //   sectionContent.blogCardStyle === 'with_image'
                    //     ? '4px solid #FFCB66'
                    //     : '4px solid #BC4034',
                    color:
                      sectionContent?.blockStyle === 'default'
                        ? 'highlightText.color2'
                        : '#BC4034',
                    fontWeight: '600',
                  }}
                  borderBottom={'none'}
                  _hover={{
                    // borderBottom:
                    //   sectionContent.blogCardStyle === 'with_image'
                    //     ? '4px solid #FFCB66'
                    //     : '4px solid #BC4034',
                    color:
                      sectionContent?.blockStyle === 'default'
                        ? 'highlightText.color2'
                        : '#BC4034',
                  }}
                >
                  {tab?.tabTitle}
                </CustomTab>
              ))}
            </TabList>
          </Box>
          {/* <TabIndicator
              height="4px"
              maxW="100%"
              minW={{ base: '1px', md: '1px', lg: '169px' }}
              bg={
                sectionContent.blogCardStyle === 'with_image'
                  ? '#FFCB66'
                  : '#BC4034'
              }
              display={{ base: 'none', md: 'block' }}
            /> */}
          <TabPanels w="100%" px={0} mt="-3px">
            {sectionContent?.tabs.map(tabPanel => (
              <TabPanel key={nanoid()} w="100%" px="0">
                <Box
                  py="0"
                  mt={
                    // sectionContent.blogCardStyle === 'with_image'?
                    '-13px'
                    // : 0
                  }
                >
                  <BlogCard
                    sectionContent={sectionContent}
                    post={tabPanel?.cards}
                    tab={tabPanel}
                    blogCardStyle={sectionContent?.blogCardStyle}
                    blockStyle={sectionContent?.blockStyle}
                    region={region}
                    lang={lang}
                    // bgSize="100% 100%"
                  />
                  {tabPanel?.showViewAllLink && (
                    <Box
                      w="100%"
                      textAlign="center"
                      mb={{ base: '40px', md: '46px' }}
                    >
                      <NextLink
                        href={refineUrlDomain(
                          tabPanel?.link[0]?.linkUrl,
                          region,
                          lang
                        )}
                        passHref
                        legacyBehavior>
                        <Link
                          style={{ fontVariant: 'small-caps' }}
                          color="#BC4034"
                          textDecor="underline"
                          fontSize={{ md: '21px' }}
                          lineHeight={{ md: '27px' }}
                          fontStyle="normal"
                          fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
                          fontWeight="600"
                        >
                          {tabPanel?.link[0]?.buttonText}
                        </Link>
                      </NextLink>
                    </Box>
                  )}
                </Box>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default LingaBhairaviBlogHomeFacadePlaceholder;
