/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { TbExclamationMark } from 'react-icons/tb';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Services */
/* Styles */
import styles from '@lb/components/Message/Message.module.css';

/**
 * Renders Important Message Component
 *
 * @param {sectionContent} sectionContent
 * @returns {ReactElement} Important Message
 */

const ImportantMessage = ({ sectionContent }) => {
  return (
    <>
      <Box
        maxW={601}
        w="100%"
        px={{ base: '10px', md: '0' }}
        mx="auto"
        mb={{ base: '0px', md: '30px' }}
        mt="20px"
        display="flex"
      >
        <Box maxW={570} pt="17px">
          <Box
            display="flex"
            border="2px solid #823333"
            borderRadius="50%"
            // fontSize={{ base: '20px', md: '30px' }}
            w="20px"
            h="20px"
            color="#823333"
            fontFamily="FedraSansStd-Book"
            bg="#FFCB66"
            textAlign="center"
            alignContent="center"
            justifyContent="center"
            marginRight={{ base: '10px', md: '14.33px' }}
            transform={{ base: 'rotate(0deg)', md: 'rotate(180deg)' }}
          >
            <TbExclamationMark />
          </Box>
        </Box>
        <StructuredTextParser
          m="0"
          mt="12px"
          str={render(sectionContent?.text)}
          className={styles.message}
        />
      </Box>
    </>
  );
};

export default ImportantMessage;
