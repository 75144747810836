/* Built In Imports */
/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import SimpleButton from '@lb/components/Buttons/SimpleButton';

/* Styles */
/* Services */
/**
 * return VolunteerAd Banner
 *
 * @param sectionContent
 * @returns {ReactElement} - DSVBanner
 */

const VolunteerAd = ({ sectionContent, region, lang, pType, section }) => {
  return (
    <>
      <Box
        bgImage={{
          base: `url(${config.imgPath}/d/46272/1681122298-group-10202-1.jpg)`,
          md: `url(${config.imgPath}/d/46272/1681122292-group-10158-1.jpg)`,
          lg: `url(${config.imgPath}/d/46272/1681122292-group-10158-1.jpg)`,
        }}
        bgSize="cover"
        backgroundPosition={{ base: 'top', md: 'right', xl: 'center' }}
        m="0 auto"
        maxW={pType === 'home' ? '100%' : '1330px'}
        w="100%"
        position="relative"
        height={pType === 'home' && section.viewH ? '100vh' : '100%'}
        py={50}
        display="flex"
        alignItems="center"
      >
        <Box
          maxW="1170px"
          width="100%"
          mx="auto"
          p={{ md: '0 15px', lg: '0 30px' }}
        >
          {sectionContent?.style === 'LingaBhairaviHomepage' && (
            <Box
              height="68%"
              w="100%"
              backgroundSize="100%"
              background="linear-gradient(180deg, rgba(132, 3, 7, 0) 0%, rgba(0, 0, 0, 0.56) 100%)"
              right="0"
              backgroundPosition={{ base: 'top right', md: 'center' }}
              top={{ base: '60%', md: '32%' }}
              left="0"
              bottom="0"
              position="absolute"
              mixBlendMode="darken"
              backgroundRepeat="no-repeat"
            ></Box>
          )}
          <Box w="100%" h="auto" position="relative">
            <Flex
              flexWrap="wrap"
              w="auto"
              mx="auto"
              flexDir={{ base: 'column-reverse', lg: 'row', xl: 'row' }}
              alignItems="center"
              justifyContent="space-between"
              pr={{ base: '0px', lg: '40px' }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                px={{ base: '15px', md: '0' }}
                maxW={{ base: 'auto', lg: '400px', xl: '422px' }}
                // maxW="auto"
                w="100%"
              >
                <Flex
                  flexDir="column"
                  pt={{ base: '30px', xl: 0 }}
                  w={{
                    base: '95%',
                    sm: '400px',
                    md: '343px',
                    lg: '100%',
                    xl: '300px',
                  }}
                >
                  <Text
                    color="title.light"
                    fontFamily="FedraSerifAStdBook"
                    fontStyle="normal"
                    fontWeight="350"
                    fontSize={{ base: '26px', lg: '32px' }}
                    lineHeight={{ base: '45px', lg: '34px' }}
                    mb="0px"
                    as="h3"
                  >
                    {sectionContent?.subtext}
                  </Text>
                  <Text
                    color="title.light"
                    fontFamily="FedraSerifDisplay"
                    fontStyle="normal"
                    fontWeight="600"
                    lineHeight={{ base: '45px', lg: '68.48px' }}
                    fontSize={{ base: 'h5', lg: 'h3' }}
                    mb={{ base: '24px', lg: '16px' }}
                    as="h2"
                  >
                    {sectionContent?.title}
                  </Text>
                  <Text
                    color="title.light"
                    fontSize={{ base: 'body2', lg: '18px' }}
                    mb="32px"
                    fontFamily="FedraSerifAStdBook"
                    fontStyle="normal"
                    fontWeight="350"
                    lineHeight={{ base: '26px', lg: '28px' }}
                  >
                    {sectionContent?.description}
                  </Text>
                  <Box alignSelf={{ base: 'center', lg: 'start' }}>
                    <SimpleButton
                      style={{
                        buttonStyle: 'LB-Default',
                        buttonText: sectionContent?.ctaLabel,
                        linkUrl: sectionContent?.ctaLink,
                      }}
                      region={region}
                      lang={lang}
                      target="_blank"
                    />
                  </Box>
                </Flex>
              </Box>
              <Box
                height="100%"
                pb={pType === 'home' && section.viewH ? '0px' : '30px'}
              >
                <Box
                  pos="relative"
                  zIndex={2}
                  height={{
                    base: '240px',
                    sm: '360px',
                    md: '334px',
                    lg: '450px',
                    xl: '500px',
                  }}
                  w={{
                    base: '100%',
                    sm: '100%',
                    md: '357px',
                    lg: '500px',
                    xl: '535px',
                  }}
                  maxW="535px"
                >
                  <LazyLoadImageComponent
                    h="100%"
                    src={sectionContent?.desktopImage?.url}
                    alt={sectionContent?.desktopImage?.alt}
                    title={sectionContent?.desktopImage?.title}
                    w="100%"
                    objectFit="cover"
                    pos="relative"
                    zIndex={2}
                    boxShadow={{
                      base: '20px 20px rgba(0, 0, 0, 0.5)',
                      md: '40px 40px rgba(0, 0, 0, 0.5)',
                    }}
                  />
                  {/* <Box
                    bg="rgba(0, 0, 0, 0.43)"
                    width={{ base: '90%', lg: '100%' }}
                    height="100%"
                    zIndex="1"
                    pos="absolute"
                    left={{ base: 'unset', lg: '30px' }}
                    bottom="-30px"
                    d={{
                      base:
                        sectionContent?.style === 'LingaBhairaviHomepage'
                          ? 'block'
                          : 'none',
                      md: 'block',
                    }}
                    right={{ base: '15%', lg: '-30px' }}
                  /> */}
                </Box>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VolunteerAd;
