/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { FaChevronRight } from 'react-icons/fa';

/* Internal Imports */
import { refineUrlDomain } from '@lb/components/Utility/utils';
/* Components */

/* Styles */
/* Services */

/**
 * Renders Visit LB Card Group Component
 *
 * @param {object} sectionContent
 * @param region
 * @param lang
 * @returns {ReactElement} Visit LB Card Group component
 */

const VisitLBCardGroup = ({
  sectionContent,
  region,
  lang,
  justifyContent,
  alignItems,
  textAlign,
}) => {
  return <>
    {sectionContent?.cards?.map((card, i) => {
      if (card?.style === 'align-right') {
        return (
          <Box
            key={nanoid()}
            backgroundImage={{
              md: card?.desktopImage?.url,
              base: card?.mobileImage?.url || card?.desktopImage?.url,
            }}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            width="100%"
            height={{ base: '269px', md: '490px' }}
            position="relative"
            mb={{ base: "20px", md: "0" }}
          >
            <Flex
              justifyContent={justifyContent || 'flex-end'}
              alignItems={alignItems || 'flex-end'}
              w="100%"
              height="100%"
            >
              <Flex
                background=" linear-gradient(270deg, rgba(0, 0, 0, 0.72) 8.22%, rgba(0, 0, 0, 0) 90.58%)"
                textAlign={textAlign || 'right'}
                height="100%"
                w="auto"
                maxW={{ base: '112.83px', md: '315px' }}
                flexDir="column"
                alignItems="flex-end"
                justifyContent="flex-end"
                p={{ base: '16.95px', md: '40px' }}
              >
                <Text
                  textTransform="uppercase"
                  fontStyle="normal"
                  fontFamily="'FedraSerifAStdBook'"
                  fontWeight="350"
                  fontSize={{ base: '16.512px', md: '27px' }}
                  lineHeight={{ base: '16px', md: '26px' }}
                  color="#B9874C"
                  width={{ base: '110px', md: '218px' }}
                  justifyContent="flex-end"
                  px={{ base: '6.95px', md: '10px' }}
                  as="h3"
                >
                  {card.titleSubtext}
                </Text>
                <Flex
                  color="#FFC475"
                  justifyContent={'flex-end'}
                  alignItems="center"
                  w={{ base: '100px', md: '245px' }}
                >
                  <NextLink
                    href={refineUrlDomain(card?.targetUrl, region, lang)}
                    passHref
                    legacyBehavior>
                    <Link minWidth={{ base: '89px', md: '117px' }}>
                      <Flex flexDirection="row" flexWrap="wrap">
                        <Text
                          fontFamily="FedraSerifDisplay"
                          fontStyle="normal"
                          fontWeight="600"
                          as="h2"
                          fontSize={{ base: '29.3547px', md: '80px' }}
                          lineHeight={{ base: '37px', md: '100px' }}
                          textDecor="underline"
                          color="#FFC475"
                          justifyContent="flex-end"
                          // width='200px'
                        >
                          {card.title}
                        </Text>
                        <Box
                          height="auto"
                          paddingTop={{ base: '5px', md: '11px' }}
                          my="auto"
                          display="flex"
                          alignItems="center"
                          color="#FFC475"
                          fontSize={{ base: '22px', md: '34px' }}
                          // justifyContent="flex-end"
                          justifyContent="center"
                          //  paddingTop={4}
                        >
                          <FaChevronRight style={{ fontSize: 'inherit' }} />
                        </Box>
                      </Flex>
                    </Link>
                  </NextLink>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        );
      }

      if (card?.style === 'align-left') {
        return (
          <Box
            key={i}
            backgroundImage={{
              md: card?.desktopImage?.url,
              base: card?.mobileImage?.url || card?.desktopImage?.url,
            }}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            width="100%"
            height={{ base: '269px', md: '490px' }}
            // maxHeight=
            position="relative"              
            mb={{ base: "20px", md: "0" }}
          >
            <Flex
              justifyContent={justifyContent || 'flex-start'}
              alignItems={alignItems || 'flex-start'}
              w="100%"
              height="100%"
            >
              <Flex
                background="linear-gradient(90deg, rgba(211, 188, 160, 0.93) 0%, rgba(243, 223, 183, 0) 92.32%)"
                textAlign={textAlign || 'left'}
                height="100%"
                maxW={{ base: '296px', md: '520px' }}
                flexDir="column"
                //   alignItems="flex-end"
                justifyContent="flex-end"
                p={{ base: '16.95px', md: '40px' }}
              >
                <Text
                  textTransform="uppercase"
                  fontStyle="normal"
                  fontFamily="'FedraSerifAStdBook'"
                  fontWeight="350"
                  fontSize={{ base: '16.512px', md: '27px' }}
                  lineHeight={{ base: '16px', md: '26px' }}
                  color="#855014"
                  width="140px"
                  as="h3"
                >
                  {card.titleSubtext}
                </Text>
                <Flex
                  color="#65000F"
                  justifyContent="flex-start"
                  alignItems="center"
                  w="100%"
                >
                  <NextLink
                    href={refineUrlDomain(card?.targetUrl, region, lang)}
                    passHref
                    legacyBehavior>
                    <Link>
                      <Flex flexDirection="row" flexWrap="wrap">
                        <Text
                          fontFamily="FedraSerifDisplay"
                          fontStyle="normal"
                          fontWeight="600"
                          fontSize={{ base: '29.3547px', md: '80px' }}
                          lineHeight={{ base: '37px', md: '100px' }}
                          textDecor="underline"
                          color="#65000F"
                          as="h2"
                        >
                          {card.title}
                        </Text>
                        <Box
                          height="100%"
                          paddingTop={{ base: '4px', md: '15px' }}
                          my="auto"
                          display="flex"
                          alignItems="center"
                          color="#65000F"
                          fontSize={{ base: '22px', md: '34px' }}
                          justifyContent="center"
                          as="span"
                          // paddingTop={{base:'2', md:'4'}}
                        >
                          <FaChevronRight style={{ fontSize: 'inherit' }} />
                        </Box>
                      </Flex>
                    </Link>
                  </NextLink>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        );
      }
    })}
  </>;
};

export default VisitLBCardGroup;
