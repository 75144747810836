/* Built In Imports */

/* External Imports */
import { Box, Heading } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import BlogTopics from '@lb/components/Chips/BlogTopics';

/* Services */

/**
 *
 * @param {object} sectionContent
 * @returns
 */

const LBBlogTopics = ({
  sectionContent,
  region,
  lang
}) => {
  return (
    <>
      <Box w="100%" maxW="1210px" m="0 auto" px="17px" mb="70px">
        <Heading
          as="h4"
          fontSize={{ base: '26px', md: '32px' }}
          lineHeight={{ base: '34px', md: '42px' }}
          mt="60px"
          mb="15px"
          color="#65000F"
          fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
          fontWeight="600"
        >
          {sectionContent?.title}
        </Heading>
        <BlogTopics
          topics={sectionContent?.topics}
          region={region}
          lang={lang}
        />
      </Box>
    </>
  );
};

export default LBBlogTopics;
