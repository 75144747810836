/* Built In Imports */
/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Styles */
/* Services */

/**
 * Renders LBTempleAddressTiming Component
 *
 * @param {object} sectionContent
 * @param region
 * @param lang
 * @returns {ReactElement} LBTempleAddressTiming component
 */

const LBTempleAddressTiming = ({ sectionContent, region, lang }) => {
  return (
    <>
      <Box
        maxW="1330px"
        w="100%"
        margin="auto"
        // background={{
        //   md: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%), url(/assets/images/offeringBg.png)',
        //   base: 'linear-gradient(180deg, rgba(239, 221, 155, 0.36) 0%, rgba(214, 164, 118, 0) 100%);'
        // }}
        pt={{ base: '30px', md: '30px' }}
        // pb={{ base: '60px', md: '80px' }}
      >
        <Flex
          w="100%"
          height="auto"
          maxW="801px"
          margin="20px auto"
          alignItems="center"
          pos="relative"
        >
          <Box
            pos="absolute"
            // w={{ base: '220', md: '375px' }}
            left={{ base: '5px', md: '-57px' }}
            display={{ base: 'none', md: 'block' }}
          >
            <LazyLoadImageComponent
              src={`${config.staticPath}/d/46272/1684434688-visitdevi-flower.png`}
              maxHeight={{ base: '123.54px', md: '180px' }}
            />
          </Box>
          <Box
            background="quoteGradient.yellow"
            BackgroundBlendMode="multiply"
            width={{ base: '434px', md: '100%' }}
            ml={{ base: '0px', md: '0' }}
            mr={{ base: '5px' }}
            p={{ base: '40px 0 38px 30px', md: '44px 20px 44px 100px' }}
          >
            <Text
              as="h6"
              fontSize={{ base: 'body1', md: 'h6' }}
              color="quoteText.color1"
              fontFamily="FedraSerifAStdBook"
              fontWeight="350"
              maxW="640px"
              width={{ base: '310', md: '770px' }}
              lineHeight={{ base: '25.2px', md: '44.29px' }}
            >
              <Box
                color="#65000F"
                w={{ base: '100%' }}
                h={{ base: '26px' }}
                fontWeight="600"
                fontSize={{ base: '20px', md: '26px' }}
                lineHeight={{ base: '130%' }}
              >
                {sectionContent?.title}
              </Box>
              <Box
                fontSize={{ base: '16px', md: '20px' }}
                fontFamily="FedraSerifAStdBook"
                color="#49423B"
                fontStyle="normal"
                lineHeight={{ md: '160%' }}
                fontWeight={{ md: '350' }}
                marginTop={{ base: '2', md: '5' }}
              >
                <StructuredTextParser
                  str={render(sectionContent?.description)}
                  region={region}
                  sectionContent={sectionContent}
                  lang={lang}
                  maxW="auto"
                  p="0"
                />
              </Box>

              <Flex marginTop="30px" flexDir={{ base: 'column', md: 'row' }}>
                <Flex flexDir="column" marginTop={{ base: '10px' }}>
                  <Box
                    color="#65000F"
                    fontSize="20px"
                    lineHeight="160%"
                    fontWeight="600"
                  >
                    {sectionContent?.column1Title}
                  </Box>
                  {render(sectionContent?.column1Text) &&
                    render(sectionContent?.column1Text) !== '<p></p>' && (
                      <Box
                        fontSize={{ base: '16px', md: '20px' }}
                        fontFamily="FedraSerifAStdBook"
                        color="#49423B"
                        lineHeight={{ md: '160%' }}
                        fontWeight={{ md: '350' }}
                      >
                      
                        <StructuredTextParser
                          str={render(sectionContent?.column1Text)}
                          region={region}
                          sectionContent={sectionContent}
                          lang={lang}
                          color='#49423B'
                          p="0"
                          lineHeight='30px'
                          fontSize={{ base: '16px', md: '20px' }} className='TemClass'/>
                        <style jsx>
                          {`
                          
                          .TemClass{
                           color: red;
                          }
                          `}
                        </style>
                       
                      </Box>
                    )}
                </Flex>

                <Flex
                  flexDir="column"
                  marginLeft={{ md: '10' }}
                  marginTop={{ base: '30px', md: '10px' }}
                >
                  <Box
                    color="#65000F"
                    position="relative"
                    fontSize="20px"
                    lineHeight="160%"
                    fontWeight={{ base: '600' }}
                  >
                    {sectionContent?.column2Title}
                  </Box>

                  {render(sectionContent?.column2Text) &&
                    render(sectionContent?.column2Text) !== '<p></p>' && (
                      <Box
                        fontSize={{ base: '16px', md: '20px' }}
                        fontFamily="FedraSerifAStdBook"
                        color="#49423B"
                        lineHeight={{ md: '160%' }}
                        fontWeight={{ md: '350' }}
                        className="templeText"
                      >
                        <StructuredTextParser
                          str={render(sectionContent?.column2Text)}
                          region={region}
                          sectionContent={sectionContent}
                          lang={lang}
                          p="0"
                        />
                      </Box>
                    )}
                </Flex>
              </Flex>
            </Text>
            <style>
              {`
              blockquote span {
                display: block;
                margin: 20px 0 0;
              }
              .templeText p {
                margin-bottom: 0;
              }
            `}
            </style>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default LBTempleAddressTiming;
