/* Built In Imports */
/* External Imports */
import { Box, Flex, Link, List, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';
/* Components */
/* Styles */
/* Services */

/**
 * Returns Explore More About Devi Slider Component
 *
 * @param root0
 * @param root0.sectionContent
 * @param root0.background
 */
const CardInfo3ColumnGroup = ({ sectionContent }) => {
  const SliderCard = ({ card }) => {
    return (
      <>
        <Box
          width={{
            base: '100%',
            sm: '320px',
            md: '320px',
            lg: '320px',
            xl: '380px',
          }}
          mr={{ base: '14px', md: '34px' }}
          minH={{ base: '409px', md: '486px' }}
          position="relative"
          bg="rgba(255, 255, 255, 1)"
          border="1px solid #f2f2f2"
          boxShadow="0px 0px 10.9956px 2.7489px rgba(0, 0, 0, 0.12)"
          p={{ base: '20px', md: '24px' }}
          my="20px"
        >
          <LazyLoadImageComponent
            src={card?.thumbnail?.url}
            maxH="198px"
            alt={card?.thumbnail?.alt}
            w="100%"
            objectFit="cover"
          />
          <Box>
            <Flex
              // position="absolute"
              bottom="0px"
              left="0px"
              flexDir="column"
              justifyContent="center"
              // alignItems="flex-end"
            >
              <Box>
                <Box>
                  <Text
                    fontSize={{ base: '20px', md: '26px' }}
                    color="headingText.dark"
                    lineHeight={{ base: '28px', md: '36.4px' }}
                    fontFamily="FedraSerifAStdBook', system-ui, sans-serif"
                    mt={{ base: '24.76px', md: '30px' }}
                  >
                    {card?.title}
                  </Text>
                </Box>
                <Box pt="12px" pb="21px">
                  <Text
                    color="bodyText.color2"
                    fontSize={{ base: 'body3', md: 'body2' }}
                    lineHeight={{ base: '22px', md: '23.2px' }}
                    fontFamily="FedraSansStd-book"
                  >
                    <StructuredTextParser
                      str={render(card?.description)}
                      className="CardInfo3"
                      textAlign={card?.style || 'left'}
                      color="bodyText.color2"
                      fontSize={{ base: 'body3', md: 'body2' }}
                      lineHeight={{ base: '22px', md: '23.2px' }}
                      fontFamily="FedraSansStd-book"
                    />
                  </Text>
                  <style>
                    {`
                      .CardInfo3 p{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        font-family:"FedraSansStd-book";
                        color:#8D7452;
                        line-height: 23px;
                      }
                      .CardInfo3{
                        padding:0;
                        margin:0;
                      }
                      @media (max-width:600px){
                        .CardInfo3 p{
                        font-family:"FedraSansStd-book";
                        color:#8D7452;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                      }
                      }
                      `}
                  </style>
                </Box>
              </Box>
              <Link
                href={card?.buttonLink}
                fontFamily="FedraSansStd-medium"
                color="#DB3030"
                textDecor="underline"
                fontSize={{ base: 'body3', md: 'body2' }}
              >
                {card?.buttonText}
              </Link>
            </Flex>
          </Box>
        </Box>
      </>
    );
  };
  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign={'center'}
        top="45%"
        zIndex="10"
        cursor="pointer"
        border="0"
        w="48px"
        h="48px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        right={{ xl: '43px', base: '3px' }}
        bgSize="13px"
        onClick={onClick}
        background="#D09700"
        alignItems="center"
        justifyContent="center"
        display={{ base: 'none', md: 'flex' }}
        boxShadow="0px 28.8px 53.6px rgba(0, 0, 0, 0.2), 0px 18.6667px 31.3907px rgba(0, 0, 0, 0.151852), 0px 11.0933px 17.0726px rgba(0, 0, 0, 0.121481), 0px 5.76px 8.71px rgba(0, 0, 0, 0.1), 0px 2.34667px 4.36741px rgba(0, 0, 0, 0.0785185), 0px 0.533333px 2.10926px rgba(0, 0, 0, 0.0481481)"
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.6 15L15 8.63975M15 8.63975L8.6 2.2795M15 8.63975L2.2 8.63975"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="45%"
        zIndex="10"
        cursor="pointer"
        bgSize="13px"
        background="#D09700"
        alignItems="center"
        justifyContent="center"
        display={{ base: 'none', md: 'flex' }}
        boxShadow="0px 28.8px 53.6px rgba(0, 0, 0, 0.2), 0px 18.6667px 31.3907px rgba(0, 0, 0, 0.151852), 0px 11.0933px 17.0726px rgba(0, 0, 0, 0.121481), 0px 5.76px 8.71px rgba(0, 0, 0, 0.1), 0px 2.34667px 4.36741px rgba(0, 0, 0, 0.0785185), 0px 0.533333px 2.10926px rgba(0, 0, 0, 0.0481481)"
        border="0"
        w="48px"
        h="48px"
        textAlign="center"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        left={{ xl: '-25px', base: '3px' }}
        onClick={onClick}
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.4 2L2 8.36025M2 8.36025L8.4 14.7205M2 8.36025H14.8"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    );
  };

  const settingsSmall = {
    dots: false,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    slidesToShow: 2,
    slidesToScroll: 1,

    appendDots: dots => (
      <Box mt="20px" className="something" pt="40px">
        <List
          style={{ margin: '0px' }}
          className="dots"
          margin="-11px"
          height="auto"
          minH="30px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {dots}
        </List>
      </Box>
    ),

    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerPadding: 0,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrow: false,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: i => (
      <Box
        bg="#9E2828"
        w="16px"
        rounded="full"
        height="16px"
        marginTop="40px"
      ></Box>
    ),
  };
  return (
    <>
      <Box
        maxW="1400px"
        margin="0 auto"
        maxH={{ base: '700px', md: 'auto' }}
        backgroundImage=""
        py="40px"
        pl={{ lg: '0', xl: '10%' }}
      >
        <Box
          w={{ base: '100%', lg: '100%', xl: '100%' }}
          height="auto"
          maxH={{ md: '600px', base: '700px' }}
          className="EDSlider"
          position="relative"
          _after={{
            content: "''",
            position: 'absolute',
            width: '340px',
            marginLeft: 'auto',
            height: '100%',
            top: 0,
            bottom: 0,
            right: 0,
            background:
              'linear-gradient(270deg, rgba(236, 236, 236, 0.9) 32.72%, rgba(217, 217, 217, 0) 97.29%)',
          }}
        >
          <Slider {...settingsSmall} w="100%">
            {sectionContent?.cards?.map(card => {
              return (
                <>
                  <SliderCard key={nanoid()} card={card} />
                </>
              );
            })}
          </Slider>

          <style>
            {`
            .EDSlider .slick-slider{
              max-height:600px;
            }
            .EDSlider::after{
              content: "";
            position: absolute;
            width: 340px;
            margin-left: auto;
            height: 100%;
            top: 0;
            bottom: 0;
            right: 0;
            background:
              linear-gradient(270deg, rgba(236, 236, 236, 0.9) 32.72%, rgba(217, 217, 217, 0) 97.29%)';
            }

          .EDSlider .slick-list .slick-active {
                    width: 414px !important;
                  }
                  @media only screen and (max-width:1000px){
                    .EDSlider .slick-list .slick-active {
                     width: 414px !important;
                    }
                  }
                  @media only screen and (max-width:800px){
                    .EDSlider .slick-list .slick-active {
                     width: 360px !important;
                    }
                    .EDSlider::after{
                      display:none !important;
                      content:"";
                      background:none;
                    }
                    .EDSlider .slick-slider{
              max-height:500px;
            }
                  }
                  @media only screen and (max-width:600px){
                    .EDSlider .slick-list .slick-active {
                     width: 340px !important;
                     margin-right: 20px;
                    }
                    
                  }
                  @media only screen and (max-width:400px){
                    .EDSlider .slick-list .slick-active {
                     width: 300px !important;
                     margin-right: 16px;
                    }
                  }
                  @media only screen and (max-width:300px){
                    .EDSlider .slick-list .slick-active {
                     width: 280px !important;
                    }
                  }
                  .EDSlider .slick-dots li.slick-active div{
                    position:relative;
                  }
                  .EDSlider .slick-dots li.slick-active div::before{
                    content:"";
                    position:absolute;
                    top:-7px;
                    left:-7px;
                    border: 3px solid #FFBC00;
                    width:30px;
                    height:30px;
                    background:transparent;                
                    transform: rotate(180deg);
                    border-radius:100%;
                   
                  } 
            
          `}
          </style>
        </Box>
      </Box>
    </>
  );
};
export default CardInfo3ColumnGroup;
