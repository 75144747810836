/* Built In Imports */
import React from 'react';
/* External Imports */
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';
import SimpleButton from '@lb/components/Buttons/SimpleButton';

/* Styles */

/* Services */

const SliderCard = ({ slider, region, lang, sectionId, section }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box w="100%" h="100%" position="relative" id={sectionId}>
      <Flex
        pos="relative"
        bgRepeat="no-repeat"
        bgImage={{
          base:
            slider?.content[3]?.mobileImage?.url ||
            slider?.content[3]?.image?.url,
          lg:
            slider?.content[3]?.mobileImage?.url ||
            slider?.content[3]?.image?.url,
          xl: slider?.content[3]?.image?.url,
        }}
        bgSize="cover"
        bgPos="center"
        zIndex={2}
        flexWrap="wrap"
        flexDir={{
          base: 'column-reverse',
          md: 'column-reverse',
          lg: 'row',
          xl: 'row',
        }}
        alignItems="center"
        alignContent={{
          base: 'space-between',
          lg: 'center',
          xl: 'center',
        }}
        h={{ base: '100%', lg: '100vh' }}
        justifyContent={{
          base: 'space-around',
          md: 'center',
        }}
        p={{ base: '15px', md: '20px', xl: '10%' }}
      >
        <Box
          maxW="1140px"
          w="100%"
          mx="auto"
          height="auto"
          display="flex"
          justifyContent="center"
          flexDir={{ base: 'column-reverse', lg: 'row' }}
          alignItems="center"
          gridGap={{ base: '30px', md: '80px' }}
        >
          <Box
            maxW="500px"
            // minW={{ base: '1px', md: '447px' }}
          >
            <Text
              fontFamily="fedraSerifDisplay"
              fontStyle="normal"
              fontSize={{ base: 'h5', lg: 'h3' }}
              fontWeight="600"
              lineHeight={{ base: '48px', lg: '68.40px' }}
              mb="10px"
              color="headingText.light"
            >
              {slider?.content[0]?.text}
            </Text>
            <Text
              fontFamily="'FedraSerifAStdBook'"
              fontSize={{ base: 'body1', lg: '20px' }}
              fontWeight="500"
              letterSpacing={{ base: '0.2em', lg: '0.2em' }}
              fontStyle="normal"
              lineHeight={{ base: '20.82px', lg: '22.8px' }}
              mb={{ base: '20px', lg: '20px' }}
              textTransform="uppercase"
              color="secondary.main"
            >
              {slider?.content[1]?.text}
            </Text>
            <Text
              fontFamily="'FedraSerifAStdBook'"
              fontSize={{ base: 'body2', lg: 'body1' }}
              fontWeight={{ base: '350', lg: '350' }}
              lineHeight={{ base: '26px', lg: '28.44px' }}
              mb={{ base: '30px', lg: '40px' }}
              color="bodyText.color3"
            >
              {slider?.content[2]?.text}
            </Text>
            <Box display="flex" justifyContent={{ base: 'center', md: 'left' }}>
              <SimpleButton
                style={{
                  buttonStyle: 'LB-Default',
                  buttonText: slider?.content[4]?.buttonText,
                  linkUrl: slider?.content[4]?.linkUrl,
                }}
                region={region}
                lang={lang}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            width={{ base: '100%', md: '100%', lg: '50%' }}
            h={{ base: '50%', lg: '100%' }}
            py={{ base: section.viewH ? '0px' : '75px', md: 'auto' }}
          >
            <Box
              bgImage={`url(${config.imgPath}/d/46272/1681403568-zzz.png)`}
              w={{ base: '171px', md: '233px' }}
              h={{ base: '168px', md: '230px' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundSize="cover"
            >
              <Box
                width={{ base: '68.39px', md: '68.39px', lg: '94px' }}
                h={{ base: '68.39px', md: '68.39px', lg: '94px' }}
                background="rgba(255, 254, 254, 0.8)"
                maxW="94px"
                overflow="hidden"
                rounded="full"
                display="grid"
                placeItems="center"
                textAlign="center"
                px="8px"
                onClick={onOpen}
                cursor="pointer"
              >
                <Text
                  fontSize={{ base: '11.64px', lg: 'body2' }}
                  lineHeight={{ base: '14px', lg: '19px' }}
                  fontStyle=""
                  fontFamily=""
                  textAlign="center"
                  fontFeatureSettings="case on"
                >
                  Click for 3D View
                </Text>
              </Box>
            </Box>
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              size={useBreakpointValue({ base: 'full', md: '6xl' })}
              isCentered
            >
              <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
              <ModalContent
                p={0}
                m="0"
                bg={{ base: '#000000' }}
                // transform={{ base: 'rotate(270deg)', md: 'none' }}
                maxW={{ base: 'auto', md: '992px' }}
                // maxH={{ base: 'auto', md: '587px' }}
              >
                <ModalCloseButton
                  _hover={{ bg: '#fff', color: '#000' }}
                  _active={{ bg: '#fff', color: '#000' }}
                  _focus={{ bg: '#fff', color: '#000' }}
                  zIndex="20"
                  top={{ base: '40px', md: '-5px' }}
                  bg="#fff"
                  color="#000"
                  right={{ base: '3px', md: '-5px' }}
                  rounded="full"
                  // className={styles.closeButton}
                />
                <ModalBody p={0} m="0" w="100%" position="relative">
                  <Box
                    pos={{ base: 'static', md: 'static' }}
                    left="50%"
                    right="50%"
                    // transform={{ md: 'none', base: 'translate(-50%, -50%)' }}
                    display={{ base: 'flex', md: 'block' }}
                    justifyContent={{ base: 'center' }}
                    alignItems={{ base: 'center' }}
                    h={{ base: '648px', md: '648px' }}
                  >
                    <Box
                      bg="#FFFFFF"
                      onClick={onClose}
                      w="32px"
                      h="32px"
                      rounded="full"
                      _hover={{ bg: '#FFFFFF' }}
                      display={{ base: 'grid', md: 'none' }}
                      placeItems="center"
                      pos="absolute"
                      top="-145px"
                      left="-185px"
                      fontFamily="FedraSansStd-Book"
                      zIndex="10"
                      cursor="pointer"
                      color="black"
                    >
                      x
                    </Box>

                    <Box
                      as="iframe"
                      src={slider?.content[5]?.linkUrl}
                      w="100%"
                      h={{ base: '100vh', md: '100%' }}
                    ></Box>
                  </Box>
                  {/* </Box> */}
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
export default SliderCard;
