/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';

/* External Imports */
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import { TiLocationOutline } from 'react-icons/ti';

/* Components */
/* Internal Imports */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import StructuredTextContent from '@lb/Common/StructuredTextContent';
import BackIcon from '@lb/components/Icons/BackIcon';
import ShareIcon from '@lb/components/Icons/ShareIcon';

/* Styles */
import styles from '@lb/components/Banners/TopBanner.module.css';

/**
 * Returns the Top Banner Event .
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Content from API
 * @param {string} region - Region of page
 * @param {string} language - Lang of page
 * @returns {ReactElement} TopBannerEvent component
 */

const TopBannerEvent = ({
  sectionContent,
  region,
  language,
  pageName,
  locations,
}) => {
  const currentUrl = useRouter();
  const url = currentUrl.asPath;

  return (
    <Box
      maxW="1330px"
      w="100%"
      pos="relative"
      margin={{ base: '0 auto 40px', md: '0 auto 80px' }}
      width="100%"
      minH={{ base: '256px', md: '490px' }}
      height="auto"
      // id="BannerContainer"
    >
      <Box
        backgroundImage={{
          base:
            sectionContent?.mobileImage?.url ||
            sectionContent?.desktopImage?.url,
          md: sectionContent?.desktopImage?.url,
        }}
        backgroundSize="cover"
        backgroundPosition="center"
        h="100%"
        minH={{ base: '256px', md: '490px' }}
        display="flex"
        alignItems="flex-end"
        justifyContent="flex-end"
        className={styles.before}
        position="relative"
        zIndex="2"
        pt="0"
        _before={{
          content: '""',
          // height: '30%',
          position: 'absolute',
          maxW: '236px',
          width: '20%',
          height: { base: '70px', md: '150px' },
          top: '100%',
          zIndex: 0,
          left: '0',
          // background: 'bannerGradient.bannerOrange',
          // transform: 'matrix(-1, 0, 0, 1, 0, 0)',
          // mixBlendMode: 'normal',
          // backgroundBlendMode: 'multiply',
        }}
      >
        <Flex
          alignSelf="flex-start"
          // width="100%"
          position="absolute"
          top="30px"
          left="64px"
          right="45px"
          justifyContent="flex-start"
          display={{ base: 'none', md: 'flex' }}
        >
          {pageName != 'iso-bs' && sectionContent.showBackButton && (
            <BackIcon region={region} language={language} />
          )}
          {pageName === 'iso-bs' && (
            <NextLink
              href={`/linga-bhairavi/${region}/${language}/`}
              passHref
              legacyBehavior
            >
              <Link cursor={'pointer'}>
                <LazyLoadImageComponent
                  width={{ base: '88px', md: '163px' }}
                  height={{ base: '43px', md: '80px' }}
                  src={`${config.imgPath}/d/46272/1701775608-lb_logo.png`}
                  alt="back"
                />
              </Link>
            </NextLink>
          )}
          {sectionContent.showShareButton && (
            <Box as="span" ml="15px">
              <ShareIcon url={url} background="#A9392D" />
            </Box>
          )}
        </Flex>
        <Box
          w={{ base: '100%', md: '80%' }}
          padding={{ base: '17px 16px 10px', md: '38px 59px 20px' }}
          maxW="904px"
          // h={{ base: 'auto', md: '530px' }}
          h="auto"
          // left={{ base: '16px', md: '15%' }}
          // py={{ base: '30px', md: '50px' }}
          // right={{ base: '16px', md: '15%' }}
          margin={{ base: '40px 16px 0px', md: '20px auto 0px' }}
          top={{ base: 'auto', md: '0px' }}
          pos="relative"
          display="grid"
          placeItems="inherit"
          // bottom={{ base: '-20px', md: '-50px' }}
          background={{
            base: 'linear-gradient(0deg, rgba(86, 8, 0, 0.8) 0%, rgba(86, 8, 0, 0.1) 100%)',
            md: 'linear-gradient(90deg, rgba(86, 8, 0, 0.8) 0%, rgba(86, 8, 0, 0.1) 100%)',
          }}
          backgroundBlendMode="multiply"
          mixBlendMode="normal"
          _before={{
            content: "''",
            width: '100%',
            height: { base: '15px', md: '40px' },
            position: 'absolute',
            bottom: { base: '-15px', md: '-40px' },
            left: '0',
            background:
              'linear-gradient(90deg, rgba(210, 100, 26, 0.8) 0%, rgba(210, 100, 26, 0) 100%)',
            backgroundBlendMode: 'multiply',
          }}
        >
          <Box
            w="100%"
            height="auto"
            maxW="652px"
            display="flex"
            justifyContent="flex-start"
            flexDir="column"
          >
            {sectionContent?.title && (
              <Text
                fontSize={{ base: '32px', md: 'h5', lg: 'h2' }}
                as="h1"
                p={0}
                m={0}
                lineHeight={{
                  base: '34px',
                  sm: '34px',
                  md: '60px',
                  lg: '75px',
                  xl: '85px',
                }}
                color="title.light"
                maxW={{ base: '261px', md: '652px', lg: '652px' }}
                fontFamily="'FedraSerifDisplay', serif"
              >
                {sectionContent?.title}
              </Text>
            )}
            {render(sectionContent?.titleSubtext) &&
              render(sectionContent?.titleSubtext) !== '<p></p>' && (
                <Box
                  id="event-description"
                  fontSize={{ base: '16px', md: '18px' }}
                  fontFamily="'FedraSansStd-book', sans-serif"
                  color="#333333"
                >
                  {StructuredTextContent(sectionContent.titleSubtext)}
                </Box>
              )}
            {render(sectionContent?.description) &&
              render(sectionContent?.description) !== '<p></p>' && (
                <Box
                  id="event-description"
                  fontSize={{ base: '16px', md: '18px' }}
                  fontFamily="'FedraSansStd-book', sans-serif"
                  color="#333333"
                >
                  {StructuredTextContent(sectionContent.description)}
                </Box>
              )}
            <style>
              {`
              .before:before{
                height:150px;
                bottom:-150px;
              }
                  #event-description p{
                    font-family: 'FedraSansStd-book', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 120%;
                    margin-top:10px;
                    color: #FFCB66;
                  }
                  #event-description h6 {
                    font-family: 'FedraSansStd-book', sans-serif;
                    font-size: 24px;
                    color: #ffffff;
                    padding-top: 15px;
                  }
                  @media (max-width:730px){
                    .before:before{
                        height:70px;
                        width:70px;
                        bottom:-70px;
                      }
                    #event-description h6 {
                      font-size: 18px;
                    }
                  }
                  @media (max-width:660px){
                    #event-description p{
                      font-family: 'FedraSansStd-book', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 120%;
                    margin-top:7px;
                    color: #FFCB66;
                    }
                  }
                  `}
            </style>
            {render(sectionContent?.date) &&
              render(sectionContent?.date) !== '<p></p>' && (
                <Box
                  fontSize={{ base: 'body1', md: 'body1', lg: 'h6' }}
                  // as="h6"
                  textAlign="left"
                  fontFamily={'"FedraSansStd-medium", sans-serif'}
                  fontWeight="500"
                  color="highlightText.color1"
                  // mt={{ base: '11px', md: '20px' }}
                >
                  {StructuredTextContent(sectionContent.date)}
                </Box>
              )}
            {/* {sectionContent?.button?.length > 0 && (
              <Button variant="primary" w="auto" mt={8}>
                Register
              </Button>
            )} */}
            {render(sectionContent?.closedMessage) &&
              render(sectionContent?.closedMessage) !== '<p></p>' && (
                <Box
                  id="event-description"
                  fontSize={{ base: '16px', md: '18px' }}
                  fontFamily="'FedraSansStd-book', sans-serif"
                  color="#333333"
                >
                  {StructuredTextContent(sectionContent.closedMessage)}
                </Box>
              )}
            {render(sectionContent?.bottomText) &&
              render(sectionContent?.bottomText) !== '<p></p>' && (
                <Box
                  id="event-description"
                  fontSize={{ base: '16px', md: '18px' }}
                  fontFamily="'FedraSansStd-book', sans-serif"
                  color="#333333"
                >
                  {StructuredTextContent(sectionContent.bottomText)}
                </Box>
              )}
            {locations?.length > 0 && (
              <Box mt={{ base: '15px', md: '12.42px' }}>
                <Text
                  color="#EBDEDE"
                  fontFamily="'FedraSansStd-book', sans-serif"
                  fontStyle="normal"
                  fontWeight="400"
                  fontSize={{ base: '12px', md: 'body2' }}
                  lineHeight={{ base: '16.61px', md: '24.91px' }}
                  textTransform="uppercase"
                  fontFeatureSettings="'case' on"
                >
                  <TiLocationOutline
                    style={{
                      color: '#DF6F3B',
                      display: 'inline',
                      fontSize: '20px',
                      marginTop: '-2px',
                    }}
                  />{' '}
                  {locations?.map((loc, index) => {
                    return (
                      index < 3 && (
                        <Box as="span" key={nanoid()}>
                          {loc.title}
                          {locations.length - 1 > index ? ',' : ''}&nbsp;
                        </Box>
                      )
                    );
                  })}
                  {locations?.length > 3 && (
                    <Box as="span">+ {locations?.length - 3} LOCATIONS</Box>
                  )}
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Flex
        pos="absolute"
        top="0"
        right={0}
        display={{ base: 'flex', md: 'none' }}
        left="0"
        padding="15px"
        width="100%"
        h="auto"
        alignItems="center"
        justifyContent="space-between"
        color="#F5D5A9"
      >
        <Box fontSize="18px" cursor="pointer" pos="relative" zIndex={10}>
          {pageName != 'iso-bs' && (
            <BackIcon background="none" region={region} language={language} />
          )}
          {pageName === 'iso-bs' && (
            <NextLink
              href={`/linga-bhairavi/${region}/${language}/}`}
              passHref
              legacyBehavior
            >
              <Link cursor={'pointer'}>
                <LazyLoadImageComponent
                  width={{ base: '88px', md: '163px' }}
                  height={{ base: '43px', md: '80px' }}
                  src={`${config.imgPath}/d/46272/1701775663-lb_logo_mobile.png`}
                  alt="back"
                />
              </Link>
            </NextLink>
          )}
        </Box>
        <Box fontSize="18px" cursor="pointer" pos="relative" zIndex={10}>
          <ShareIcon background={'none'} url={url} />
        </Box>
      </Flex>
    </Box>
  );
};

export default TopBannerEvent;
