/* Built In Imports */
/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Components */
import StructuredTextParser from './Utility/StructuredTextParser';
/* Services */
/* Styles */

/**
 *
 * @param sectionContent
 * @param region
 * @param language
 */

const LBhowToParticipateGroup = ({ sectionContent, language, region }) => {
  return (
    <>
      <Box maxW="696px" margin="0 auto" px={{ md: '0', base: '15px' }} className='howtoparticipate'>
        <Text
          as="h3"
          fontSize={{ base: '26px', md: '32px' }}
          fontFamily="FedraSansStd-A-medium"
          fontWeight={500}
          color="headingText.main"
        >
          {sectionContent.title}
        </Text>
        {sectionContent.steps.map((step, index) => {
          return (
            <Box key={nanoid()} display="flex" flexDir="row">
              <Text
                w="auto"
                minW="80px"
                color={{ base: '#49423B', md: 'headingText.dark' }}
                fontWeight="bold"
                mt={4}
                fontSize={{ base: '18px', md: '20px' }}
              >
                {step.stepLabel}
              </Text>

              <StructuredTextParser
                str={render(step.stepDescription)}
                region={region}
                lang={language}
                id={'stepDescription'}
                textAlign={sectionContent.style}
                mt="15px"
              />
              <style jsx global>
                {`
                  #stepDescription p {
                    margin-bottom: 10px;
                  }
                  @media only screen and (max-width: 600px) {
                    #stepDescription p {
                      margin-bottom: 14px;
                    }
                  }
                `}
              </style>
            </Box>
          );
        })}
        <Box className="LBHowToParticipate">
          <StructuredTextParser
            str={render(sectionContent.footerContent)}
            region={region}
            textAlign="center"
            lang={language}
            fontSize="18px"
            fontWeight="bold"
            // textAlign={sectionContent.style}
          />
        </Box>
      </Box>
    </>
  );
};
export default LBhowToParticipateGroup;
