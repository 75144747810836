/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Services */

/**
 * Renders the SectionTitle component.
 *
 * @param titleObj.titleObj
 * @param {object} titleObj component.
 * @param {object} rest some more data
 * @param titleObj.section
 * @returns {ReactElement} SectionTitle component.
 */

const SectionTitle = ({ titleObj, pageName, ...props }) => {
  const titleColor = {
    'no-decoration': '#02026c',
    'LB-Default': '#65000F',
  };

  return (
    <>
      {(titleObj?.titleText || titleObj?.title) && (
        <Box
          className={`title-${titleObj?.titleDecoration || 'default'}`}
          maxW={{ base: '100%', md: props.w || '663px' }}
          mx={{ base: 'none', md: 'auto' }}
          fontFamily="FedraSerifDisplay"
          fontSize={{
            base: titleObj?.fontSize || 'h6',
            md: titleObj?.fontSize || 'h4',
          }}
          lineHeight={{ base: '29.6px', md: '70px' }}
          color={
            titleObj?.color ||
            titleColor[titleObj?.titleDecoration] ||
            '#65000F'
          }
          textAlign={{ base: pageName == 'iso-bs' ? 'center' : 'left', md: 'center' }}
          mt={{
            base: props.mt || '30px',
            md: props.mt || '40px',
          }}
          h="auto"
          fontWeight="600"
          {...props}
        >
          <Box as="h2">
            <Box>{titleObj?.titleText || titleObj?.title}</Box>
          </Box>
          <Box textAlign={{ base: 'left', md: 'center' }} mb="15px">
            {RenderBorder(titleObj?.titleDecoration)}
          </Box>
        </Box>
      )}
    </>
  );
};
export default SectionTitle;
const RenderBorder = style => {
  style = style || 'default';
  const imgPath = {
    'no-decoration': '',
    default: '',
  };
  return (
    imgPath[style] && (
      <LazyLoadImageComponent
        alt="divider"
        title="divider"
        minHeight="20px"
        src={
          imgPath[style]?.indexOf('https://') < 0
            ? `${config.staticPath}/d/46272/${imgPath[style]}`
            : imgPath[style]
        }
      />
    )
  );
};
