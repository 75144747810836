/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
import { refineUrlDomain } from '@lb/components/Utility/utils';
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Styles */
/* Services */

/**
 * @param sectionContent
 */

const EventWaysToCelebrate = ({ sectionContent, region, lang }) => {
  const SliderCard = ({ card }) => {
    return <>
      <Box
        width={{
          base: '93%',
          sm: '300px',
          md: '340px',
          lg: '400px',
        }}
        height={{ base: '455px', md: '547px' }}
        position="relative"
      >
        <LazyLoadImageComponent
          src={card?.thumbnail?.url}
          alt={card?.thumbnail?.alt}
          title={card?.thumbnail?.title}
          objectFit="cover"
          h="100%"
          w="100%"
        />
        <Box>
          <Flex
            position="absolute"
            bottom="0px"
            left="0px"
            flexDir="column"
            justifyContent="center"
            alignItems="flex-end"
            bgGradient="linear(to-b, #0000, #000)"
          >
            <Box px={{ base: '19px', md: '22px' }}>
              <Box>
                <Text
                  fontSize={{ base: '32px', md: 'h5' }}
                  color="title.light"
                  lineHeight={{ base: '34px', md: '48px' }}
                  marginBottom="10px"
                  padding="0"
                  fontFamily="FedraSerifDisplay"
                >
                  {card?.title}
                </Text>
              </Box>
              <Box mb="16px">
                <Text
                  color="bodyText.color3"
                  fontSize="body2"
                  lineHeight={{ base: '22px', md: '26px' }}
                >
                  <StructuredTextParser
                    className="EventWaysText"
                    str={render(card.description)}
                    // region={region}
                    // sectionContent={sectionContent}
                    // lang={language}
                    color="white"
                    lineHeight={{ base: '22px', md: '26px' }}
                    textAlign={sectionContent.style}
                  />
                  <style>
                    {`
                      .EventWaysText {
                        padding:0;
                        margin:0;

                      }
                      .EventWaysText p {                
                        color:#F8F8F8;
                        font-style: normal;
                        font-weight: 350;
                        font-size: 16px;
                        line-height: 26px;
                        font-family:'FedraSerifAStdBook';
                      }
                      @media (max-width:600){
                        .EventWaysText{
                        padding:0;
                        margin:0;
                      }
                      .EventWaysText p{
                        font-style: normal;
                        font-weight: 350;
                        font-size: 14px;
                        line-height: 22px;
                      }
                      }
                      
                      `}
                  </style>
                </Text>
              </Box>
            </Box>
            {card.buttonLink && (
              <Button variant="secondary" w="100%" h="52px" fontSize="body2">
                <NextLink
                  // was not working so
                  href={refineUrlDomain(card?.buttonLink, region, lang)}
                  passHref
                  legacyBehavior>
                  <Link
                    color="#ffffff"
                    // href={url}
                    textDecor="none"
                    _hover={{ textDecor: 'none' }}
                    fontWeight="500"
                  >
                    {card.buttonText}&nbsp;&nbsp;
                  </Link>
                </NextLink>
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
    </>;
  };

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign={'center'}
        top="45%"
        zIndex="10"
        cursor="pointer"
        border="0"
        w="48px"
        h="48px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        right={{ xl: '30px', base: '3px' }}
        bgSize="13px"
        onClick={onClick}
        background="#FFFFFF"
        placeItems="center"
        display={{ base: 'none', md: 'grid' }}
        boxShadow="0px 28.8px 53.6px rgba(0, 0, 0, 0.2), 0px 18.6667px 31.3907px rgba(0, 0, 0, 0.151852), 0px 11.0933px 17.0726px rgba(0, 0, 0, 0.121481), 0px 5.76px 8.71px rgba(0, 0, 0, 0.1), 0px 2.34667px 4.36741px rgba(0, 0, 0, 0.0785185), 0px 0.533333px 2.10926px rgba(0, 0, 0, 0.0481481)"
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.6 15L15 8.63975M15 8.63975L8.6 2.2795M15 8.63975L2.2 8.63975"
            stroke="#2E2E2E"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        display={{ base: 'none', md: 'grid' }}
        top="45%"
        zIndex="10"
        cursor="pointer"
        bgSize="13px"
        background="#FFFFFF"
        placeItems="center"
        boxShadow="0px 28.8px 53.6px rgba(0, 0, 0, 0.2), 0px 18.6667px 31.3907px rgba(0, 0, 0, 0.151852), 0px 11.0933px 17.0726px rgba(0, 0, 0, 0.121481), 0px 5.76px 8.71px rgba(0, 0, 0, 0.1), 0px 2.34667px 4.36741px rgba(0, 0, 0, 0.0785185), 0px 0.533333px 2.10926px rgba(0, 0, 0, 0.0481481)"
        border="0"
        w="48px"
        h="48px"
        textAlign="center"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        left={{ xl: '0px', base: '3px' }}
        onClick={onClick}
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.4 2L2 8.36025M2 8.36025L8.4 14.7205M2 8.36025H14.8"
            stroke="#2E2E2E"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    );
  };

  const settingsSmall = {
    dots: false,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    slidesToShow: sectionContent?.cards?.length < 2 ? 1 : 2,
    slidesToScroll: 2,
    centerMode: false,
    centerPadding: '25px',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: sectionContent?.cards?.length < 2 ? 1 : 2,
          slidesToScroll: 2,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: sectionContent?.cards?.length < 2 ? 1 : 2,
          slidesToScroll: 2,
          centerPadding: '20px',
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '16px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '16px',
        },
      },
    ],
  };

  return (
    <Box py="40px" maxW="1330px" w="100%" mx="auto">
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        flexWrap={{ base: 'wrap', lg: 'wrap', xl: 'nowrap' }}
        justifyContent="space-between"
      >
        <Box
          w={{ base: '100%', lg: '100%', xl: '30%' }}
          pb={{ base: '20px', md: '0' }}
        >
          <Flex
            flexDirection={{
              base: 'row-reverse',
              lg: 'row-reverse',
              xl: 'column',
            }}
            width="100%"
            h="100%"
            alignItems="center"
            justifyContent="center"
            float="left"
            textAlign={{ base: 'left', lg: 'left', xl: 'right' }}
          >
            <Box
              maxW="228px"
              maxH="auto"
              padding="0 5px"
              alignSelf="flex-end"
              display="flex"
              justifyContent="flex-end"
              alignItems={{
                base: 'flex-start',
                nd: 'self-end',
                xl: 'self-end',
              }}
              minW="90px"
              pb="10px"
            >
              <LazyLoadImageComponent
                src={`${config.staticPath}/d/46272/1679654805-devi-website_illustrations.png`}
                w="80px"
                h="80px"
              />
            </Box>
            <Box px={{ base: '15px', md: 0 }}>
              <Text
                as="h3"
                fontSize={{ base: 'h5', md: 'h4', xl: 'h3' }}
                textAlign={{ base: 'left', lg: 'left', xl: 'right' }}
                color="#823333"
                fontFamily="FedraSerifDisplay"
                fontStyle="normal"
                fontWeight="600"
                lineHeight={{ base: '48px', md: '70px' }}
              >
                {sectionContent?.title}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          w={{ base: '100%', lg: '100%', xl: '65%' }}
          height="auto"
          position="relative"
          maxW="890px"
        >
          {/* <Slider> */}
          <Slider {...settingsSmall}>
            {sectionContent?.cards?.map((card) => {
              return <SliderCard key={nanoid()} card={card} />;
            })}
          </Slider>
        </Box>
      </Flex>
    </Box>
  );
};

export default EventWaysToCelebrate;
