/* Built In Imports */
import React from 'react';
/* External Imports */
import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { TiLocationOutline } from 'react-icons/ti';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import SimpleButton from '@lb/components/Buttons/SimpleButton';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Styles */

/* Services */
const SlideCardImage = ({
  slider,
  region,
  lang,
  sectionId,
  // pType,
  section,
}) => {
  return (
    <>
      <Box
        w="100%"
        h={section.viewH ? '100vh' : 'auto'}
        position="relative"
        id={sectionId}
      >
        <Flex
          bgRepeat="no-repeat"
          bgImage={{
            base:
              slider?.content[4]?.mobileImage?.url ||
              slider?.content[4]?.image?.url,
            lg:
              slider?.content[4]?.mobileImage?.url ||
              slider?.content[4]?.image?.url,
            xl: slider?.content[4]?.image?.url,
          }}
          bgSize="cover"
          bgPos="center"
          pos="relative"
          zIndex={2}
          flexWrap="wrap"
          flexDir={{
            base: 'column-reverse',
            md: 'column-reverse',
            lg: 'row',
            xl: 'row',
          }}
          h={{ base: '100%', lg: '100vh' }}
          alignItems="center"
          alignContent="center"
          justifyContent={{
            base: 'space-around',
            md: 'center',
          }}
          p={{ base: '15px', md: '20px', xl: '10%' }}
        >
          <Box
            maxW="1140px"
            w="100%"
            mx="auto"
            display="flex"
            height="auto"
            alignItems="center"
            justifyContent="center"
            flexDir={{ base: 'column-reverse', lg: 'row' }}
            gridGap={{ base: '30px', md: '80px' }}
          >
            <Box maxW="500px">
              <Text
                fontFamily="fedraSerifDisplay"
                fontStyle="normal"
                fontSize={{ base: 'h5', lg: 'h3' }}
                fontWeight="600"
                lineHeight={{ base: '48px', lg: '68.40px' }}
                mb={{ base: '10px', lg: '10px' }}
                color="headingText.light"
              >
                {slider?.content[0]?.text}
              </Text>
              <Text
                fontFamily="'FedraSerifAStdBook'"
                fontSize={{ base: 'body1', lg: '20px' }}
                fontWeight="500"
                letterSpacing={{ base: '0.2em', lg: '0.2em' }}
                fontStyle="normal"
                lineHeight={{ base: '20.82px', lg: '22.8px' }}
                mb={{ base: '20px', lg: '20px' }}
                textTransform="uppercase"
                color="secondary.main"
              >
                {slider?.content[1]?.text}
              </Text>

              <Text
                fontFamily="'FedraSerifAStdBook'"
                fontSize={{ base: 'body2', lg: 'body1' }}
                fontWeight={{ base: '350', lg: '350' }}
                lineHeight={{ base: '26px', lg: '28.44px' }}
                mb={{ base: '18px', lg: '17.67px' }}
                color="bodyText.color3"
                maxW={{ base: 339, md: 405 }}
              >
                <StructuredTextParser
                  mt="0"
                  mb="0"
                  px="0"
                  id="bhairaviAdobeText"
                  str={render(slider?.content[2]?.body)}
                />
                <style>
                  {`
                    #bhairaviAdobeText p{
                      color:#FFFFFF;
                      font-size:16px;
                      line-height:26px;
                    }
                    #bhairaviAdobeText p em{
                      color:#FFFFFF;
                      font-size:16px;
                      line-height:26px;
                    }
                    `}
                </style>
              </Text>
              <Text
                fontFamily="FedraSansStd-book"
                fontSize={{ base: 'body3', lg: 'body3' }}
                fontWeight={{ base: '350', lg: '350' }}
                lineHeight={{ base: '26px', lg: '28.44px' }}
                mb={{ base: '30px', lg: '65px' }}
                color="bodyText.color3"
                display={{ base: 'none', md: 'flex' }}
                alignItems="center"
                textTransform="uppercase"
              >
                <Text
                  as="span"
                  style={{
                    color: '#DF6F3B',
                    display: 'inline',
                    fontSize: '14px',
                    marginTop: '-2px',
                  }}
                >
                  <TiLocationOutline
                    style={{
                      display: 'inline',
                    }}
                  />
                </Text>{' '}
                &nbsp;
                {slider?.content[8]?.text}
              </Text>
              <Box
                display="flex"
                justifyContent={{ base: 'center', md: 'left' }}
              >
                <SimpleButton
                  style={{
                    buttonStyle: 'LB-Default',
                    buttonText: slider?.content[3]?.buttonText,
                    linkUrl: slider?.content[3]?.linkUrl,
                  }}
                  region={region}
                  lang={lang}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              width={{ base: '100%', md: '100%', lg: '50%' }}
              px={{ base: '20px', md: '0' }}
              h={{ base: '55%', lg: '100%' }}
              py={{ base: section.viewH ? '0px' : '60px', md: 'auto' }}
            >
              <Box
                maxW={{ base: 'auto', md: 500 }}
                border="solid 12px #BC4034"
                maxH={{ base: '315px', md: '315px', lg: '315px', xl: '500px' }}
                overflow={'hidden'}
                boxSizing="border-box"
              >
                <Flex m="0" p="0" gridGap="0" flexDir="column">
                  <Flex
                    height="50%"
                    w="100%"
                    p="0"
                    m="0"
                    flexWrap="no-wrap"
                    justifyContent="flex-start"
                    alignContent="stretch"
                    alignItems="stretch"
                    gridGap="0"
                    maxH={{
                      base: '148.94px',
                      md: '148.94px',
                      lg: '148.94px',
                      xl: '236.41px',
                    }}
                  >
                    <LazyLoadImageComponent
                      src={useBreakpointValue({
                        base:
                          slider?.content[5]?.mobileImage?.url ||
                          slider?.content[5]?.image?.url,
                        xl: slider?.content[5]?.image?.url,
                      })}
                      alt={slider?.content[5]?.image?.alt || ''}
                      w="60%"
                      h="100%"
                      p="0"
                      objectFit="fill"
                      flex={1}
                      m="0 !important"
                      border="none"
                    />

                    <LazyLoadImageComponent
                      h="100%"
                      border="none"
                      src={useBreakpointValue({
                        base:
                          slider?.content[7]?.mobileImage?.url ||
                          slider?.content[7]?.image?.url,
                        xl: slider?.content[7]?.image?.url,
                      })}
                      alt={slider?.content[7]?.image?.alt || ''}
                      w="40%"
                      flex={1}
                      p="0"
                      m="0 !important"
                      objectFit="fill"
                    />
                  </Flex>
                  <Flex
                    height="50%"
                    w="100%"
                    p="0"
                    border="none"
                    margin="0"
                    maxH={{
                      base: '148.94px',
                      md: '148.94px',
                      lg: '148.94px',
                      xl: '236.41px',
                    }}
                  >
                    <LazyLoadImageComponent
                      src={useBreakpointValue({
                        base:
                          slider?.content[6]?.mobileImage?.url ||
                          slider?.content[6]?.image?.url,
                        xl: slider?.content[6]?.image?.url,
                      })}
                      alt={slider?.content[6]?.image?.alt || ''}
                      w="auto"
                      h="100%"
                      p="0"
                      m="0 !important"
                      objectFit="cover"
                    />
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
};
export default SlideCardImage;
