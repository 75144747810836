/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/* Styles */
/* Services */

/**
 * Renders StickyGroup Component
 *
 * @param {object} Button Group
 * @returns {ReactElement} StickyGroup Component
 */

const StickyGroup = ({ sectionContent }) => {
  const colors = ['#AE3A2E', '#366445', '#BC831C']; // Array of colors for buttons

  return (
    <>
      <Box
        display="flex"
        textAlign="center"
        m={{ base: '0 auto 0', md: '-89px auto 60px auto' }}
        w={{ base: '100%', md: '812px' }}
        flexDirection={'row'}
        alignItems="center"
        maxW={{ base: 'unset', md: '812px' }}
        // textTransform={'uppercase'}
        fontFamily={'FedraSansStd-A-medium'}
        fontSize={{ base: '16px', md: '18px' }}
        lineHeight={{ base: '20px' }}
        sx={{
          'a:first-letter': {
            fontSize: '18px',
          },
          body: {
            padding: '0 0 68px !important',
          },
        }}
        position={{ base: 'fixed', md: 'relative' }}
        bottom={{ base: '0', md: 'unset' }}
        zIndex={'1000'}
        justifyContent="center"
      >
        {sectionContent.buttonGroup.map((button, index) => (
          <>
          {button.buttonText !== '' && (
          <Box
            width={{ base: '264px' }}
            background={colors[index]}
            color={'#ffffff'}
            minH={{ base: '68px', md: '68px' }}
            mr={{ base: '0', md: '9px' }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            key={index}
          >
            <NextLink
              href={button.linkUrl ? button.linkUrl : '#'}
              passHref
              legacyBehavior
            >
              <Link
                mx="auto"
                w="100%"
                variant="primary"
                textDecor="none"
                _hover={{ textDecor: 'none' }}
                _focus={{ textDecor: 'none' }}
                _active={{ textDecor: 'none' }}
                target={
                  button.linkUrl?.indexOf('http') > -1 &&
                  button.linkUrl?.indexOf(`${config.cdnPath}/linga-bhairavi`) <
                    0
                    ? '_blank'
                    : ''
                }
              >
                {' '}
                {button.buttonText}
              </Link>
            </NextLink>
          </Box>
          )}
          </>
        ))}
      </Box>
      <style>
        {`
              .footer{
                padding: 0 0 68px;
              }
                  `}
      </style>
    </>
  );
};

export default StickyGroup;
