/* Built In Imports */
/* External Imports */
import { Box, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */

/**
 * Renders Top Banner Simple Component
 *
 * @param {object} sectionContent - data from api
 * @returns Top Banner Simple Component
 */

const TopBannerSimple = ({ sectionContent }) => {
  // consoleLog(sectionContent);
  const bgImages = {
    'no-background': ``
  };

  return (
    <Box
      h="208px"
      minH="208px"
      w="100%"
      display="table"
      position="relative"
      bgColor="#e4ded4"
      background="radial-gradient(circle at 0% 0.5%, rgb(228 222 212) 0.1%, rgb(224, 226, 228) 100.2%)"
      backgroundImage={bgImages[sectionContent.backgroundImageStyle]}
      backgroundPosition="top center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      textAlign="center"
      // mb="30px"
    >
      <Box
        display="table-cell"
        verticalAlign=" middle"
        width="100%"
      >
        <Text
          fontSize={{ base: '32px', md: '46px ', lg: '64px' }}
          as="h1"
          p={0}
          m={0}
          lineHeight={{
            base: '34px',
            sm: '34px',
            md: '60px',
            lg: '75px',
            xl: '85px',
          }}
          color="#65000f"
          fontFamily="FedraSerifDisplay"
        >
          {sectionContent?.title}
        </Text>
      </Box>
    </Box>
  );
};

export default TopBannerSimple;
