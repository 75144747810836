/* Built In Imports */
import { createContext, useEffect, useState } from 'react';

/* External Imports */
import { getCurrentRegion } from 'services/commonService';

/* Services */

export const RegionContext = createContext('');

export const RegionContextProvider = props => {
  // consoleLog('called RegionContext', props);
  const [currRegion, setCurrRegion] = useState('in');

  useEffect(() => {
    (async () => {
      /* Logging the value of currRegion and currRegion?.country.toLowerCase() */
      let cRegion;
      try {
        cRegion = await getCurrentRegion();
        setCurrRegion(cRegion?.country?.toLowerCase());
      } finally {
        if (cRegion?.length === 0) {
          setCurrRegion('in');
        }
      }
    })();
  }, []);

  return (
    <RegionContext.Provider value={currRegion}>
      {props.children}
    </RegionContext.Provider>
  );
};

export default RegionContextProvider;
