/* Built In Imports */
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';

/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import forEach from 'lodash/forEach';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import FlipCard from '@lb/components/Cards/FlipCard';

// Services
import { useFilterContext } from '@store/lb/filterContext';

/* Styles */
// import styles from '@lb/components/Cards/LingaBhairavi.module.css';

/* Services */

/**
 * Renders Linga Bhairavi Card Component
 *
 * @param {object} SectionContent - Section Content
 * @param {string} region - region
 * @param {string} lang - lang Content
 * @param {object} pageData - pageData Content
 * @returns LingaBhairaviCard Component
 */

const LingaBhairaviCard = ({
  sectionContent,
  region,
  lang,
  pageData,
  styleConfig,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const marginBottom = styleConfig?.additionalBottomPadding;
  // console.log(marginBottom);
  const router = useRouter();
  const [cards, setCards] = useState(sectionContent?.events);
  let { location, category, search, online, blocksVis, setBlocksVis } =
    useFilterContext();
  const blockStyle =
    sectionContent?.blockStyle === 'tree-decoration' ||
    sectionContent?.blockStyle === 'triangle-decoration';
  const bgBlock = {
    'tree-decoration': '1681104457-1679922897-img_1491-3.png',
    'triangle-decoration': '1684123801-mask-group.png',
  };
  const basebgBlock = {
    'tree-decoration': '1681104457-1679922897-img_1491-3.png',
    'triangle-decoration': '1683998941-devi-website_illustrations-2.png',
  };
  let isBlockVisible = true;

  const paddingTop = {
    base: blockStyle ? '37px' : '40px',
    md: blockStyle ? '37px' : '40px',
    lg: blockStyle ? '37px' : '40px',
    xl: '37px',
  };

  const textPaddingTop = {
    base: blockStyle ? '' : '40px',
    md: blockStyle ? '' : '40px',
    lg: blockStyle ? '' : '40px',
    xl: blockStyle ? '' : '80px',
  };

  const isFiltersActive =
    router.query.category ||
    router.query.location ||
    router.query.online ||
    search ||
    router.query.search;

  if (router.asPath.indexOf('/rituals') > -1) {
    if (!pageData?.overrideRegistrationLogic) {
      if (pageData.arpanam?.ritual?.locations?.length) {
        pageData.globalRegLink = pageData.arpanam?.ritual?.regLink;
        pageData.locations = pageData.arpanam?.ritual?.locations?.filter(
          loc => {
            return loc.isAvailable === true;
          }
        );
        pageData.isAvailableOnline =
          pageData.arpanam?.ritual?.isAvailableOnline;
      } else {
        pageData.locations = [];
        pageData.isAvailableOnline = false;
      }
    }

    forEach(cards, card => {
      let matchingCards = pageData?.arpanam?.rituals.filter(ritual => {
        return ritual.arpanamId === card.arpanamId;
      });
      // console.log('matchingCards', matchingCards?.length);

      if (matchingCards?.length) {
        forEach(pageData?.arpanam?.rituals, ritual => {
          if (ritual.arpanamId === card.arpanamId) {
            card.visible = true;
            if (!card?.overrideRegistrationLogic) {
              if (!ritual?.locations?.length) {
                card.visible = false;
              } else {
                let locations = [];
                forEach(ritual?.locations, loc => {
                  locations.push({ title: loc });
                });
                card.locations = locations;
                card.ritual = ritual;
                card.availableOnline = ritual?.isAvailableOnline;
              }
            }
          }
        });
      } else {
        if (!pageData?.overrideRegistrationLogic) {
          card.locations = [];
          card.availableOnline = false;
        }
      }
    });
  }

  // console.log('cardsss', cards, sectionContent.events, pageData);

  const filterData = useCallback(() => {
    if (router.query.category) {
      category = router.query.category;
    }

    if (router.query.location) {
      location = router.query.location;
    }
    if (router.query.search) {
      search = router.query.search;
    }
    online = router.query.online === 'yes';

    let finalCards = sectionContent?.events;
    let anyChange = false;

    if (online) {
      finalCards = finalCards.filter(card => {
        // console.log('card?.availableOnline', card?.availableOnline.toString(), online.toString(), card?.availableOnline.toString() == online.toString() )
        return card?.availableOnline.toString() == online.toString();
      });
      anyChange = true;
    }

    if (location) {
      finalCards = finalCards.filter(card => {
        let cardLoc = card.locations.filter(loc => {
          return (
            loc.title?.toLowerCase().trim() === location?.trim().toLowerCase()
          );
        })[0];
        return cardLoc;
      });
      anyChange = true;
      // console.log('location', finalCards?.length, finalCards);
    }

    if (category) {
      finalCards = finalCards.filter(card => {
        return card.ritualCategory?.urlAlias === category;
      });
      anyChange = true;
    }

    if (search) {
      finalCards = finalCards.filter(card => {
        return (
          card?.title?.toLowerCase().includes(search?.toLowerCase()) ||
          card?.ritualCategory?.title
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
          card?.tags?.toLowerCase().includes(search?.toLowerCase())
        );
        // ? location filter pending
      });
      anyChange = true;
    }

    if (finalCards?.length && !blocksVis) {
      setBlocksVis(blocksVis + finalCards?.length);
    }
    if (anyChange) {
      // console.log('anyChange', anyChange);
      setCards(finalCards);
    }
  }, [router.asPath]);

  useMemo(() => {
    // console.log('filterData called', cards);
    filterData();
    return () => {
      setCards([]);
    };
  }, [router.asPath]);

  if (sectionContent?.title?.toLowerCase() === 'popular' && isFiltersActive) {
    isBlockVisible = false;
  }

  if (!cards?.length && router.asPath.indexOf('/rituals') > -1) {
    isBlockVisible = false;
  }

  return (
    isBlockVisible && (
      <Box w="100%" maxW="1330px" m="0 auto">
        {(sectionContent?.title ||
          sectionContent?.blockStyle !== 'default') && (
          <Box
            maxW={1200}
            mx="auto"
            width="100%"
            pl={{
              base:
                sectionContent?.blockStyle === 'triangle-decoration'
                  ? '120px'
                  : '130px',
              md:
                sectionContent?.blockStyle === 'triangle-decoration'
                  ? '125px'
                  : '120px',
              xl: '190px',
            }}
            pos="relative"
            zIndex="1"
            pt={textPaddingTop}
            pb={{
              base: blockStyle ? '' : '15px',
              md: blockStyle ? '' : '25px',
            }}
          >
            <Text
              color="title.dark"
              fontSize={{ base: '28px', md: 'h5' }}
              fontFamily="fedraSerifDisplay"
              fontWeight={600}
              as="h3"
              lineHeight={{ base: '30px', md: '49.22px' }}
              mb={{
                base:
                  sectionContent?.blockStyle === 'tree-decoration' ||
                  sectionContent?.blockStyle === 'triangle-decoration'
                    ? '10px'
                    : '0',
                md:
                  sectionContent?.blockStyle === 'tree-decoration' ||
                  sectionContent?.blockStyle === 'triangle-decoration'
                    ? '10px'
                    : '0',
                xl:
                  sectionContent?.blockStyle === 'tree-decoration' ||
                  sectionContent?.blockStyle === 'triangle-decoration'
                    ? '12px'
                    : '0',
              }}
            >
              {sectionContent?.title}&nbsp;
              {isFiltersActive && cards.length > 0 && (
                <Text as="span">&#40;{cards.length}&#x29;</Text>
              )}
            </Text>
          </Box>
        )}
        <Box
          bg={
            'linear-gradient(180deg, rgba(212, 195, 162, 0.8) 0%, rgba(217, 217, 217, 0) 60.56%)'
          }
          backgroundSize="100%"
          pt={paddingTop || '37px'}
          pb={!marginBottom && { base: '40px', md: '40px', lg: '60px' }}
          position="relative"
          px={{ base: '16px', md: '' }}
          // className={styles.LingaBhairaviCardContainer}
          pos="relative"
          _before={{
            content: '""',
            position: 'absolute',
            display:
              sectionContent?.blockStyle === 'tree-decoration' ||
              sectionContent?.blockStyle === 'triangle-decoration'
                ? 'block'
                : 'none',
            height: '102px',
            background:
              'linear-gradient(180deg, #EBE4CB 0%, rgba(237, 233, 218, 0) 60.56%)',
            transform: 'matrix(1, 0, 0, -1, 0, 0)',
            top: '-102px',
            width: '100%',
            left: '0',
            right: 0,
            zIndex: 0,
          }}
        >
          {cards?.length > 0 && (
            <Box maxW="1330px" margin="0 auto" w="100%">
              <Flex
                flexWrap="wrap"
                justifyContent={{
                  base: 'center',
                  lg: 'center',
                  xl: 'flex-start',
                }}
                maxW="1208px"
                alignItems="flex-start"
                alignContent="flex-start"
                margin="0 auto"
                w="auto"
                pos="relative"
                // style={{ gap: '16px' }}
                gridColumnGap={{ base: '32px', md: '31px' }}
                gridRowGap={{ base: '0', sm: '21px', md: '20px' }}
              >
                {cards?.map((event, i) => {
                  return (
                    <FlipCard
                      pageData={pageData}
                      section={sectionContent}
                      summaryColor="bodyText.color2"
                      sectionContent={event}
                      key={i}
                      region={region}
                      lang={lang}
                      cardTitle={sectionContent?.title}
                      // mx={{ base: '17px', md: '17.5px' }}
                      my={{ base: '10px' }}
                    />
                  );
                })}
                {bgBlock[sectionContent?.blockStyle] && (
                  <Box
                    position="absolute"
                    top={{
                      base:
                        sectionContent?.blockStyle === 'triangle-decoration'
                          ? '-87px'
                          : '-115px',

                      md:
                        sectionContent?.blockStyle === 'triangle-decoration'
                          ? '-108px'
                          : '-115px',
                      xl:
                        sectionContent?.blockStyle === 'triangle-decoration'
                          ? '-155px'
                          : '-240px',
                    }}
                    left={{ base: '10px', md: '17px', xl: '-1px' }}
                    width={{
                      base:
                        sectionContent?.blockStyle === 'triangle-decoration'
                          ? '94px'
                          : '83px',
                      md:
                        sectionContent?.blockStyle === 'triangle-decoration'
                          ? '94px'
                          : '83px',
                      xl: '170px',
                    }}
                    height={{
                      base:
                        sectionContent?.blockStyle === 'triangle-decoration'
                          ? '83px'
                          : '122px',
                      md:
                        sectionContent?.blockStyle === 'triangle-decoration'
                          ? '83px'
                          : '122px',
                      xl:
                        sectionContent?.blockStyle === 'triangle-decoration'
                          ? '220px'
                          : '243.22px',
                    }}
                    zIndex="10"
                    userSelect="none"
                  >
                    {isMobile && (
                      <LazyLoadImageComponent
                        src={`${config.imgPath}/d/46272/${
                          basebgBlock[sectionContent?.blockStyle]
                        }`}
                        objectFit="contain"
                        width="100%"
                        height="100%"
                        userSelect="none"
                        alt="decoration"
                        draggable="false"
                        style={{
                          userSelect: 'none',
                          draggable: 'false',
                          pointerEvents: 'none',
                        }}
                      />
                    )}

                    {!isMobile && (
                      <LazyLoadImageComponent
                        src={`${config.imgPath}/d/46272/${
                          bgBlock[sectionContent?.blockStyle]
                        }`}
                        objectFit="contain"
                        width="100%"
                        height="100%"
                        userSelect="none"
                        alt="decoration"
                        draggable="false"
                        style={{
                          userSelect: 'none',
                          draggable: 'false',
                          pointerEvents: 'none',
                        }}
                      />
                    )}
                  </Box>
                )}
              </Flex>
            </Box>
          )}
          {router.asPath.indexOf('/events') > -1 && cards?.length < 1 && (
            <Box maxW="1330px" margin="0 auto" w="100%">
              <Flex
                flexWrap="wrap"
                justifyContent={{
                  base: 'center',
                  lg: 'center',
                  xl: 'space-between',
                }}
                maxW="1208px"
                alignItems="flex-start"
                alignContent="flex-start"
                margin="0 auto"
                w="auto"
                pos="relative"
                style={{ gap: '16px' }}
                pt="30px"
                pl="10px"
              >
                Sorry, no results were found. Please change the search criteria
                to see more events.
                {bgBlock[sectionContent?.blockStyle] && (
                  <Box
                    position="absolute"
                    top={
                      sectionContent?.blockStyle === 'triangle-decoration'
                        ? '-176px'
                        : '-240px'
                    }
                    left="-1px"
                    width={{ base: '0', md: '0', xl: '204px' }}
                    height={{
                      base: '0',
                      md: '0',
                      xl:
                        sectionContent?.blockStyle === 'triangle-decoration'
                          ? '200px'
                          : '243.22px',
                    }}
                    zIndex="10"
                  >
                    <LazyLoadImageComponent
                      src={`${config.imgPath}/d/46272/${
                        bgBlock[sectionContent?.blockStyle]
                      }`}
                      objectFit="contain"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                )}
              </Flex>
            </Box>
          )}
        </Box>
      </Box>
    )
  );
};

export default LingaBhairaviCard;
