/* Built In Imports */
/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import parser from 'html-react-parser';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import SimpleButton from '@lb/components/Buttons/SimpleButton';

/**
 *
 * @param {object} sectionContent
 * @returns
 */

const PromotionalBanner = ({
  sectionContent,
  region,
  language,
  mb,
  pType,
  section,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const bgStyle = {
    red: 'linear-gradient(90deg, rgba(87, 8, 8, 0.8) 43.83%, rgba(188, 64, 52, 0) 91.62%)',
    green:
      'linear-gradient(90deg, rgba(31, 67, 35, 0.8) 43.83%, rgba(54, 100, 59, 0) 91.62%)',
    yellow:
      'linear-gradient(90deg, rgba(158, 103, 20, 0.6) 36%, rgba(217, 217, 217, 0) 91.62%)',
  };

  return sectionContent ? (
    <Box
      h={{
        base: pType == 'home' && section.viewH ? '100svh' : 'auto',
        md: pType == 'home' ? '100vh' : 'auto',
      }}
      maxW={pType == 'home' ? '100%' : '1330px'}
      m="0 auto"
      position="relative"
    >
      <LazyLoadImageComponent
        src={
          isMobile
            ? sectionContent?.mobileImage?.url
            : sectionContent?.desktopImage?.url
        }
        w="100%"
        objectFit="cover"
        h={{
          base: pType == 'home' && section.viewH ? '100svh' : '660px',
          md: pType == 'home' ? '100vh' : '650px',
        }}
      />
      <Box
        position="absolute"
        top={0}
        bottom={{ base: '0px', md: '0' }}
        left="0"
        right={{ base: '0', md: '30%' }}
        display="flex"
        alignItems={{ base: 'flex-end', md: 'center' }}
      >
        <Box
          w="100%"
          h="auto"
          padding={{ base: '21px 0 0', md: '86px 45px', lg: '86px 105px' }}
          display="flex"
          flexDir="column"
          alignContent="flex-start"
          alignItems="flex-start"
          justifyContent="space-between"
          bg={{
            md: `${bgStyle[sectionContent?.style]}`,
            base: `${bgStyle[sectionContent?.style]}`,
          }}
        >
          <Box padding={{ base: '40px 20px', md: '0' }} w="100%" h="100%">
            <Box
              pl={{
                base: '0rem',
                md:
                  pType == 'home' ? 'calc( 30vw - 19rem)' : 'calc( 1vw - 9rem)',
              }}
            >
              <Text
                color="title.light"
                fontFamily="FedraSerifDisplay"
                as="h4"
                fontSize={{ base: '32px', md: 'h4' }}
                lineHeight={{ base: '34px', md: '55.64px' }}
                fontWeight="600"
                mb={{ base: '14px', md: '16px' }}
              >
                {sectionContent?.title}
              </Text>
              <Text
                color="title.light"
                fontSize={{ base: '18px', md: '26px' }}
                lineHeight={{ base: '19px', md: '31px' }}
                fontWeight="350"
                textTransform="uppercase"
                style={{ fontFeatureSettings: 'case on' }}
                mb={{ base: '13px', md: '12px' }}
              >
                {sectionContent?.subtext}
              </Text>
              <Box
                color="bodyText.color3"
                fontSize={{ base: '16px', md: '20px' }}
                lineHeight={{ base: '22px', md: '32px' }}
                fontWeight="350"
                fontStyle="normal"
                mb={{ base: '42px', md: '32px' }}
                maxW={{ sm: '392px', lg: '580px' }}
                h="auto"
              >
                {parser(render(sectionContent?.description))}
              </Box>

              <SimpleButton
                mb={mb || '0'}
                mt="0"
                style={{
                  buttonStyle: 'LB-Default',
                  buttonText: sectionContent?.ctaLabel,
                  linkUrl: sectionContent?.ctaLink,
                }}
                region={region}
                lang={language}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default PromotionalBanner;
