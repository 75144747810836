/* Built In Imports */
import React from 'react';
/* External Imports */
import { Box } from '@chakra-ui/react';
/* Internal Imports */
/* Components */
/* Styles */
/* Services */

/**
 * Renders Button Icon Component
 *
 * @returns {ReactElement} Button Icon
 */
const ButtonIcon = () => {
  return (
    <>
      <Box>
        <svg
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 11.0137L11 1.01367M11 1.01367L0.999999 1.01367M11 1.01367L11 11.0137"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    </>
  );
};

export default ButtonIcon;
