/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports*/
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

// Styles
import classes from '@lb/components/Cards/VolunteerCircularImage.module.css';

/**
 * Renders the Volunteer Circular Image Zigzag
 *
 * @param data.data
 * @param {object} data - data for section
 * @param {string} region - Region for section
 * @param {string} lang - Language for section
 * @param {object} index - index for cards
 * @returns {ReactElement} - VolunteerCircularImage card component
 */

const VolunteerCircularImage = ({ data, region, lang, index, style }) => {
  const dataTitle = style === 'lb-title-description';
  const settings = {
    dots: true,
    autoplay: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: function () {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="12px"
          h="12px"
          bgColor="#fff"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  const isEvenIndex = index % 2 == 0;
  const flexDirection = !isEvenIndex ? 'row' : 'row-reverse';
  const textAlign = isEvenIndex ? 'left' : 'right';
  const float = isEvenIndex ? 'right' : 'left';
  const background = {
    base: 'linear-gradient(90deg, rgba(239, 221, 155, 0.36) 0%, rgba(214, 164, 118, 0) 100%)',
    md: isEvenIndex
      ? 'linear-gradient(90deg, rgba(239, 221, 155, 0.36) 0%, rgba(214, 164, 118, 0) 100%)'
      : 'linear-gradient(270deg, rgba(239, 221, 155, 0.36) 0%, rgba(214, 164, 118, 0) 100%)',
  };

  return (
    <Box
      minW={{ base: '300px' }}
      maxW={{ base: '371px' }}
      w={{ base: '100%', md: '90%' }}
      display="flex"
      justifyContent={{
        base: 'center',
        md: 'center',
      }}
      alignItems={{ base: 'center', md: 'center' }}
      key={nanoid()}
      mb={{ base: 12, md: '4.5em' }}
      height='100%'
      flexDirection={{
        md: flexDirection,
        base: 'column-reverse'
      }}
    >
      <Box
        className="slider description"
        color={'black'}
        textAlign="right"
        display="flex"
        position="relative"
        zIndex="1"
        pt="200px"
        maxW="528px"
        maxH='371px'
        w={{ base: '95%' }}
        h={{ base: '100%' }}
        background={background}
        backgroundBlendMode="multiply"
        alignItems="center"
        justifyContent="center"
        mt={{
          base: '-155px',
          md: (dataTitle && isEvenIndex) ? '20px' : '90px',
        }}
        mb="-33px"
        ml={{ md: isEvenIndex ? '-60px' : '0px', base: '0', }}
        mr={{ md: !isEvenIndex ? '-60px' : '0px', base: '0', }}
      >
        <Box
          w={{ base: '100%', md: '100%' }}
          ml={{ md: isEvenIndex ? '150px' : '0px' }}
          mr={{ md: !isEvenIndex ? '150px' : '0px' }}
          mt={{ md: '-150px' }}
          pl={{ base: '10px', md: ''}}
        >
          <Box
            w={{ base: '100%', md: '100%' }}
            pl={{ base: '5px' }}
            fontFamily="FedraSansStd-A-medium, system-ui, sans-serif"
            fontSize={{ base: '23px', md: '32px' }}
            fontWeight="500"
            textAlign={{ base: 'left', md: isEvenIndex ? 'left' : 'right' }}
            color="#121212"
            lineHeight={{ base: '24px', md: '33.8px' }}
            mb={{ base: '0', md: '5%' }}
          >
            {' '}
            {data?.title}
          </Box>
          <Box

            w={{ base: '100%', md: '100%' }}
            h={{ base: '90%', md: 'auto' }}
            ml={{ base: 'auto', md: '0px' }}
            as="h4"
            fontSize={'16px'}
            pb={2}
            fontFamily={'FedraSansStd-book'}
            textAlign={{ base: 'left', md: isEvenIndex ? 'left' : 'right' }}
            className="structuredText"
          >
            <StructuredTextParser
              str={render(data.description)}
              maxW={{ base: '344px' }}
              w={{ base: '100%', md: '355px' }}
              h="auto"
              region={region}
              lang={lang}
              px="0"
              pl={{ base: '5px' }} pb={{ base: '30px' }}
              className={dataTitle ? classes.zigzagCardText : classes.wTitle}
              textAlign={{ base: 'left', md: textAlign }}
            />

            {data.buttonLink && (
              <Box>
                <NextLink
                  href={refineUrlDomain(data.buttonLink, region, lang)}
                  passHref
                  legacyBehavior
                >
                  <Link
                    bgColor="transparent"
                    color="#cf4520"
                    mr={{ md: '20px' }}
                    padding="5px 30px"
                    border="1px solid #cf4520"
                    borderRadius="5px"
                    display="inline-block"
                    my={19}
                    fontFamily="FedraSansStd-medium"
                    fontSize="13px"
                    _hover={{
                      color: '#fff',
                      bgColor: '#cf4520',
                      textDecoration: 'none',
                    }}
                    _focus={{
                      color: '#fff',
                      bgColor: '#cf4520',
                      textDecoration: 'none',
                    }}
                    _active={{
                      color: '#fff',
                      bgColor: '#cf4520',
                      textDecoration: 'none',
                    }}
                    target={
                      data?.buttonLink.indexOf('http') > -1 &&
                        data?.buttonLink.indexOf(config.cdnPath) < 0
                        ? '_blank'
                        : ''
                    }
                  >
                    {data?.buttonText}
                  </Link>
                </NextLink>
              </Box>
            )}
          </Box>
        </Box>

        <style jsx global>
          {`
            .structuredText a {
              color: #cf4824;
            }
            .structuredText a:hover {
              border-bottom: 1px solid #cf4824;
            }
          `}
        </style>
      </Box>

      <Box
        w={{ base: '95%', md: '100%' }}
        textAlign={{
          base: 'center'
        }}
      >
        {data.thumbnails.length > 1 ? (
          <Slider
            key={index}
            w={{ base: '90%', md: '356px' }}
            float={{ md: "left" }}
            minW={{ base: '270', md: '356px' }}
            mr={{ md: "70px" }}
            ml={{ base: '0', sm: '0', md: '30px' }}
            height={{ base: 'auto', md: '356px' }}
            {...settings}
          >
            {data.thumbnails.map(img => (
              <Box
                key={nanoid()}
                {...settings}
                w={{ base: '90%', md: '356px', sm: '70%' }}
              >
                <LazyLoadImageComponent
                  alt={img?.alt || ''}
                  maxW={{ base: '344px', md: '356px' }} minW={{ base: '270px' }}
                  maxH={{ base: '344px', md: '356px' }} minH={{ base: '270px' }}
                  w={{ base: '100%', md: '100%' }}
                  height={{ base: '100%', md: '100%' }}
                  src={img?.url}
                  objectFit="cover"
                />
              </Box>
            ))}
          </Slider>
        ) : (
          <LazyLoadImageComponent
            alt={data.thumbnails[0]?.alt || ''}
            title={data.thumbnails[0]?.alt}
            padding="0"
            ml={{ base: 'auto' }}
            textAlign="center"
            float={{ base: dataTitle ? 'none' : 'left', md: float }}
            //float={{ base: 'none', md: float }}
            src={data.thumbnails[0]?.url}
            position="relative"
            zIndex="2"
            maxW={{ base: '344px', md: '356px' }} minW={{ base: '270px', md: '300px' }}
            maxH={{ base: '344px', md: '356px' }} minH={{ base: '270px', md: '300px' }}
            w={{ base: '100%', md: '100%' }}
            height={{
              base: '100%',
              md: '100%',
            }}
          />
        )}
        <style jsx global>
          {`
            .slick-dots {
              bottom: 5px;
            }
            .slick-dots li.slick-active p {
              background-color: #f5ac3e;
            }
          `}
        </style>
      </Box>
    </Box>
  );
};

export default VolunteerCircularImage;


