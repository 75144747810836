/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import { FaAngleDown } from 'react-icons/fa';

/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Services */
/* Styles */

/**
 *
 * @param sectionContent
 * @param region
 * @param language
 */

const LBInPersonOfferingGroup = ({ sectionContent, region, language }) => {
  const router = useRouter();
  const [location, setLocation] = useState(
    router.query.location || 'All Locations'
  );
  const [activeCards, setActiveCards] = useState(sectionContent?.cards);
  const [isCatOpen, setCatIsOpen] = useState(false);

  const expandText = index => {
    const newCards = [...activeCards];
    newCards[index].open = true;
    setActiveCards(newCards);
  };

  const searchItems = val => {
    setLocation(val);
    if (val?.toLowerCase() === 'all locations') {
      return sectionContent?.cards;
    }
    if (val?.toLowerCase() !== 'all locations') {
      const filterCards = sectionContent?.cards.filter(card => {
        let activeLoc = card?.locations.filter(loc => {
          return loc.title?.toLowerCase() === val?.toLowerCase();
        });
        return activeLoc.length > 0;
      });
      setActiveCards(filterCards);
    } else {
      setActiveCards(sectionContent?.cards);
    }
  };

  useEffect(() => {
    let locList = sectionContent?.locationList?.filter(loc => {
      return loc.title.toLowerCase() === router.query?.location?.toLowerCase();
    });
    if (locList?.length) {
      searchItems(locList[0]?.title);
    } else {
      searchItems('All Locations');
    }
    // console.log('locList?.length', locList);
    return () => {};
  }, [router.asPath]);

  useEffect(() => {
    return () => {};
  }, [activeCards]);

  return (
    <Box
      w="100%"
      maxW="1330px"
      background="linear-gradient(180deg, rgba(212, 195, 162, 0.9) 0%, rgba(217, 217, 217, 0) 60.56%);"
      pt="45px"
      alignSelf="center"
      backgroundRepeat="no-repeat"
      backgroundSize="100% 296px"
    >
      <Box
        maxW="696px"
        margin={{ base: '0', md: '0 auto' }}
        padding={{ base: '0 0 0 17px', md: '0' }}
        width={{ base: '100%', md: '100%' }}
        display="flex"
        flexDir={{ base: 'none', md: 'row-reverse' }}
      >
        <Button
          bg="#ffffff"
          border="1px solid #B7987E"
          color="#B7987E"
          w={{ base: '164px', md: '227px' }}
          h={{ base: '36px', md: '46px' }}
          rounded="full"
          display="inline-flex"
          justifyContent="space-between"
          _hover={{ border: '1px solid #B7987E' }}
          _focus={{ border: '1px solid #B7987E' }}
          _active={{ border: '1px solid #B7987E' }}
          rightIcon={isCatOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={() => setCatIsOpen(!isCatOpen)}
          onBlur={event => {
            setCatIsOpen(false);
            event.preventDefault();
          }}
          className="selectButton"
          textTransform="capitalize"
        >
          {location?.replace(/-/g, ' ') || 'All Locations'}
          {isCatOpen && (
            <Flex
              _before={{ content: "''", height: '17px' }}
              _after={{ content: "''", height: '17px' }}
              display={isCatOpen ? 'block' : 'none'}
              _hover={{ display: 'block' }}
              className="options"
              alignItems="flex-start"
              alignContent="flex-start"
              // onMouseLeave={() => {
              //   setCatIsOpen(false);
              // }}
              onClick={() => setCatIsOpen(false)}
              position="absolute"
              top="37px"
              border="1px solid #EDD1C2"
              zIndex="10"
              left="0"
              borderRadius="14px"
              minW={{ base: '192px', md: '227px' }}
              flexDir="column"
              bg="#ffffff"
              mt="14px"
              w="auto"
              px="10px"
            >
              {location !== 'All Locations' && (
                <Box padding="8px 0px" cursor="pointer" value="">
                  <Text
                    color="#B7987E"
                    fontFamily="FedraSansStd-book"
                    textAlign="left"
                    _hover={{ color: '#A33A2C', fontWeight: '600' }}
                    textTransform="capitalize"
                    onClick={() => {
                      router.push(
                        {
                          pathname: router.asPath.split('?')[0],
                          query: {
                            location: '',
                          },
                        },
                        undefined,
                        { scroll: false }
                      );
                    }}
                  >
                    All Locations
                  </Text>
                </Box>
              )}

              {sectionContent?.locationList?.map((loc, i) => {
                return (
                  <Box
                    padding="8px 0px"
                    cursor="pointer"
                    key={i}
                    selected={loc.url}
                    value={loc.url}
                  >
                    <Text
                      color="#B7987E"
                      fontFamily="FedraSansStd-book"
                      textAlign="left"
                      _hover={{ color: '#A33A2C', fontWeight: '600' }}
                      textTransform="capitalize"
                      // onMouseEnter={() => setLocation(loc.urlAlias)}
                      onClick={() => {
                        router.push(
                          {
                            pathname: router.asPath.split('?')[0],
                            query: {
                              location: loc.title.toLowerCase(),
                            },
                          },
                          undefined,
                          { scroll: false }
                        );
                      }}
                    >
                      {loc.title}
                    </Text>
                  </Box>
                );
              })}
            </Flex>
          )}
        </Button>
      </Box>
      <Box className="loc-Offerings-container">
        {activeCards?.map((card, index) => {
          return (
            <Box
              key={nanoid()}
              maxW="972px"
              margin="0 auto"
              width={{ base: '100%', md: '100%' }}
              pt="0"
              pb={{ base: '30px', md: '60px' }}
              px={{ base: '15px', md: '0' }}
              h="auto"
              borderBottom={
                index < sectionContent?.cards.length - 1
                  ? '1px solid rgba(0, 0, 0, 0.2)'
                  : 'none'
              }
            >
              <Box mt="10">
                <Flex
                  display="flex"
                  flexDir={{ base: 'column', md: 'row' }}
                  pt={{ base: '0', md: '30px' }}
                  margin={{ base: '0', md: '0 auto' }}
                  maxW="696px"
                >
                  <Flex alignItems={{ base: 'center', md: 'flex-start' }}>
                    <LazyLoadImageComponent
                      width={{ base: '120px', md: '220px' }}
                      height={{ base: '120px', md: '220px' }}
                      padding={2}
                      src={card?.image?.url}
                      alt={card?.title}
                    />
                    <Box
                      display={{ base: 'block', md: 'none' }}
                      font-size="20px"
                      flexDirection={{ base: 'column', md: 'row' }}
                      flexWrap="wrap"
                      line-height="160%"
                      fontFamily="FedraSansStd-A-medium"
                      color="headingText.main"
                      fontWeight="500"
                      marginLeft={{ base: '10px' }}
                    >
                      {card?.title}
                    </Box>
                  </Flex>
                  <Box flexDir="col" flexWrap="wrap">
                    <Text
                      flexWrap="wrap"
                      as="h3"
                      fontSize="h6"
                      fontFamily="FedraSansStd-A-medium"
                      fontWeight={500}
                      color="headingText.main"
                      paddingLeft={2}
                      //   h='auto'
                    >
                      <Box
                        font-size="20px"
                        flexDirection={{ base: 'column', md: 'row' }}
                        flexWrap="wrap"
                        line-height="160%"
                        fontFamily="FedraSerifAStdBook"
                        fontWeight="600"
                        marginLeft={{ base: '0', md: '14px' }}
                        display={{ base: 'none', md: 'block' }}
                      >
                        {card.title}
                      </Box>

                      <Box
                        justifyContent="center"
                        display="flex"
                        flexDir={{ base: 'column', md: 'row' }}
                        flexWrap="wrap"
                        width={{ base: '100%', sm: '100%', md: '428px' }}
                        maxW="500px"
                        fontFamily="FedraSerifAStdBook"
                        fontStyle="normal"
                        fontWeight={{ md: '350' }}
                        fontSize={{ md: '20px' }}
                        lineHeight={{ md: '160%' }}
                        marginTop={{ base: '0', md: '0' }}
                        marginLeft={{ base: '0', md: '14px' }}
                      >
                        {render(card?.description) &&
                          render(card?.description) !== '<p></p>' && (
                            <Box
                              fontSize={{ base: '16px', md: '18px' }}
                              fontFamily="FedraSerifAStdBook"
                              color="#49423B"
                              width={{ base: '100%', sm: '342px', md: '428px' }}
                              height={{
                                base: card.open ? 'auto' : '212px',
                                md: card.open ? 'auto' : '150px',
                              }}
                              marginBottom={{ base: '25px' }}
                              background={
                                !card.open &&
                                render(card?.description).length > 300
                                  ? 'linear-gradient(180deg, #49423B 0%, rgba(73, 66, 59, 0) 88.79%)'
                                  : 'none'
                              }
                              sx={
                                !card.open &&
                                render(card?.description).length > 300
                                  ? {
                                      '-webkit-background-clip': 'text',
                                      '-webkit-text-fill-color': 'transparent',
                                    }
                                  : {}
                              }
                            >
                              <StructuredTextParser
                                str={render(card.description)}
                                region={region}
                                sectionContent={sectionContent}
                                lang={language}
                                p="0"
                              />
                            </Box>
                          )}
                        {!card.open && render(card?.description).length > 300 && (
                          <Flex
                            justifyContent="center"
                            textAlign={'center'}
                            position="relative"
                            w="100%"
                            mt="-40px"
                            display="flex"
                          >
                            <Link
                              //  mb="20px"
                              fontFamily="FedraSerifAStdBook"
                              fontWeight="600"
                              textTransform="uppercase"
                              borderRadius="3px"
                              bgColor="transparent"
                              color={'#cf4520 !important'}
                              fontSize="18px !important"
                              padding="10px 35px"
                              textDecoration="none !important"
                              transition="none !important"
                              textAlign="center"
                              width={'200px'}
                              display="flex"
                              alignItems="center"
                              m="0 auto"
                              onClick={() => expandText(index)}
                            >
                              <Box as="span">Expand</Box>
                              <Box as="span" ml="2px" pt="2px">
                                <FaAngleDown fontWeight="700" />
                              </Box>
                            </Link>
                          </Flex>
                        )}
                        <Box>
                          {/*  {if(coimbatoreContentVisible && card?.locations) ==='coimbatore'
        return(
          <Box>
            {card.title}
          </Box>
        ) }*/}
                        </Box>
                      </Box>
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Box>
          );
        })}
        {activeCards.length < 1 && (
          <Box
            key={nanoid()}
            maxW="972px"
            margin="0 auto"
            width={{ base: '100%', md: '100%' }}
            pt="0"
            pb={{ base: '30px', md: '60px' }}
            px={{ base: '15px', md: '0' }}
            h="auto"
          >
            <Box mt="10">
              <Box
                flexDir={{ base: 'column', md: 'row' }}
                pt={{ base: '0', md: '30px' }}
                margin={{ base: '0', md: '0 auto' }}
                maxW="696px"
                textAlign="center"
              >
                No results found.
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LBInPersonOfferingGroup;
