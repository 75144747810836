/* Built In Imports */
import React, { useEffect, useState } from 'react';
/* External Imports */
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import YouTube from 'react-youtube';

/* Internal Imports */
import { getEmbedUrl } from '@lb/components/Utility/utils';
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import SimpleButton from '@lb/components/Buttons/SimpleButton';

/* Styles */
import styles from '@lb/components/Embeds/RitualEmbed.module.css';

/* Services */
const InnerSliderCard = ({
  slider,
  region,
  lang,
  sectionId,
  // pType,
  section,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [opts, setOpts] = useState({});

  useEffect(() => {
    setOpts({
      height: document.getElementById('msr-vid')?.clientHeight,
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    });
  }, [global.window]);

  const onReady = event => {
    event.target.playVideo();
  };

  const finalRef = React.useRef(null);

  return slider.content[5]?.image?.url ? (
    <Box
      w="100%"
      h={section.viewH ? '100svh' : '100%'}
      position="relative"
      id={sectionId}
    >
      <Flex
        pos="relative"
        zIndex={2}
        flexWrap="wrap"
        h={section.viewH ? '100svh' : '100%'}
        flexDir={{
          base: 'column-reverse',
          md: 'column-reverse',
          lg: 'row',
          xl: 'row',
        }}
        alignItems="center"
        alignContent="center"
        justifyContent={{
          base: 'space-around',
          md: 'center',
        }}
        p={{ base: '15px', md: '20px', xl: '10%' }}
        bgRepeat="no-repeat"
        bgImage={{
          base:
            slider?.content[4]?.mobileImage?.url ||
            slider?.content[4]?.image?.url,
          lg:
            slider?.content[4]?.mobileImage?.url ||
            slider?.content[4]?.image?.url,
          xl: slider?.content[4]?.image?.url,
        }}
        bgSize="cover"
        bgPos="center"
      >
        <Box
          maxW="1140px"
          w="100%"
          mx="auto"
          h={{ base: '100%', lg: '100vh' }}
          display="flex"
          justifyContent="center"
          flexDir={{ base: 'column-reverse', lg: 'row' }}
          alignItems="center"
          gridGap={{ base: '30px', md: '80px' }}
        >
          <Box maxW="500px">
            <Text
              fontFamily="fedraSerifDisplay"
              fontStyle="normal"
              fontSize={{ base: 'h5', lg: 'h3' }}
              maxW="447px"
              fontWeight="600"
              lineHeight={{ base: '48px', lg: '68.40px' }}
              mb={{ base: '10px', lg: '10px' }}
              color="headingText.light"
            >
              {slider?.content[0]?.text}
            </Text>
            <Text
              fontFamily="'FedraSerifAStdBook'"
              fontSize={{ base: 'body1', lg: '20px' }}
              fontWeight="500"
              letterSpacing={{ base: '0.2em', lg: '0.2em' }}
              fontStyle="normal"
              lineHeight={{ base: '20.82px', lg: '22.8px' }}
              mb="20px"
              textTransform="uppercase"
              color="secondary.main"
            >
              {slider?.content[1]?.text}
            </Text>
            <Text
              fontFamily="'FedraSerifAStdBook'"
              fontSize={{ base: 'body2', lg: 'body1' }}
              fontWeight={{ base: '350', lg: '350' }}
              lineHeight={{ base: '26px', lg: '28.44px' }}
              mb={{ base: '30px', lg: '40px' }}
              color="bodyText.color3"
            >
              {slider?.content[2]?.text}
            </Text>
            <Box display="flex" justifyContent={{ base: 'center', md: 'left' }}>
              <SimpleButton
                style={{
                  linkUrl: slider?.content[3].linkUrl,
                  buttonStyle: 'LB-Default',
                  buttonText: slider?.content[3]?.buttonText,
                }}
                region={region}
                lang={lang}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            width={{ base: '100%', md: '100%', lg: '40%' }}
            h="auto"
            py={{ base: section.viewH ? '0px' : '85px', md: 'auto' }}
          >
            <Box
              width={{ base: '73.33px', lg: 'auto' }}
              h={{ base: '73.33px', lg: '103.16px' }}
              maxW={{ base: '73.33px', lg: '103.16px' }}
              overflow="hidden"
              rounded="full"
              display="flex"
              justifyContent="center"
              textAlign="center"
              cursor="pointer"
              onClick={onOpen}
              ref={finalRef}
            >
              <LazyLoadImageComponent
                alt="playIcon"
                src={`${config.imgPath}/d/46272/1681964046-icon.svg`}
              />
            </Box>
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              size="6xl"
              isCentered
              finalFocusRef={finalRef}
            >
              <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
              <ModalContent
                p={0}
                m="0"
                bg={{ base: '#000000' }}
                // transform={{ base: 'rotate(270deg)', md: 'none' }}
                maxW={{ base: 'auto', md: '992px' }}
                maxH={{ base: '357px', md: '587px' }}
              >
                <ModalCloseButton
                  _hover={{ bg: '#fff', color: '#000' }}
                  _active={{ bg: '#fff', color: '#000' }}
                  _focus={{ bg: '#fff', color: '#000' }}
                  zIndex="20"
                  top="-5px"
                  bg="#fff"
                  color="#000"
                  right="-5px"
                  rounded="full"
                  className={styles.closeButton}
                />
                <ModalBody
                  p={0}
                  m="0"
                  w="100%"
                  position="relative"
                  height="100%"
                >
                  <Box
                    pos={{ base: 'static', md: 'static' }}
                    left="50%"
                    right="50%"
                    // transform={{ md: 'none', base: 'translate(-50%, -50%)' }}
                    display={{ base: 'flex', md: 'block' }}
                    justifyContent={{ base: 'center' }}
                    alignItems={{ base: 'center' }}
                  >
                    <Box
                      bg="#FFFFFF"
                      onClick={onClose}
                      w="32px"
                      h="32px"
                      rounded="full"
                      _hover={{ bg: '#ffffff' }}
                      display={{ base: 'grid', md: 'none' }}
                      placeItems="center"
                      pos="absolute"
                      top="-145px"
                      left="-185px"
                      fontFamily="FedraSansStd-Book"
                      zIndex="10"
                      cursor="pointer"
                      color="black"
                    >
                      x
                    </Box>
                    <Box w="100%" height="100%">
                      <YouTube
                        videoId={getEmbedUrl(
                          slider.content[5]?.embedUrl ||
                            'https://www.youtube.com/watch?v=Kz8B20YYjOo'
                        )}
                        onReady={onReady}
                        opts={opts}
                        loading="lazy"
                        className={styles.RitualEmbed}
                      />{' '}
                    </Box>
                  </Box>
                  {/* </Box> */}
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </Box>
      </Flex>
    </Box>
  ) : null;
};

export default InnerSliderCard;
