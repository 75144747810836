/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SingleImage from '@components/Card/SingleImage';
import JsonTable from '@components/Tables/JsonTable';
// import DynamicComp from '@lb/Common/DynamicComp';
import FaqExpandedSection from '@lb/components/Accordions/FaqExpandedSection';
import FaqSectionGroupV2 from '@lb/components/Accordions/FaqSectionGroupV2';
import FaqSectionV2 from '@lb/components/Accordions/FaqSectionV2';
import VolunteerAd from '@lb/components/Banners/Ads/VolunteerAd';
import PromotionalBanner from '@lb/components/Banners/PromotionalBanner';
import TopBannerDark from '@lb/components/Banners/TopBannerDark';
import TopBannerEvent from '@lb/components/Banners/TopBannerEvent';
import TopBannerSimple from '@lb/components/Banners/TopBannerSimple';
import TopBannerWithAutoplayVideo from '@lb/components/Banners/TopBannerWithAutoplayVideo';
import RelatedContentSlider from '@lb/components/Blogs/RelatedContentSlider';
import SimpleButton from '@lb/components/Buttons/SimpleButton';
import LunarCalendar from '@lb/components/Calendar/LunarCalendar';
import CardInfo3ColumnGroup from '@lb/components/CardInfo3ColumnGroup';
import CardZigzagGroup from '@lb/components/Cards/CardZigZagGroup';
import LBInPersonOfferingGroup from '@lb/components/Cards/LBInPersonOfferingGroup';
import LBTempleAddressTiming from '@lb/components/Cards/LBTempleAddressTiming';
import LingaBhairaviCard from '@lb/components/Cards/LingaBhairaviCard';
import OfferingGroup from '@lb/components/Cards/OfferingGroup';
import Quote from '@lb/components/Cards/Quote';
import VisitLBCardGroup from '@lb/components/Cards/VisitLBCardGroup';
import LBBlogTopics from '@lb/components/Filter/LBBlogTopics';
import LingaBhairaviBlogHomeFacadePlaceholder from '@lb/components/Filter/LingaBhairaviBlogHomeFacadePlaceholder';
import LingaBhairaviFacadeBlogListingPlaceholder from '@lb/components/Filter/LingaBhairaviFacadeBlogListingPlaceholder';
import LingaBhairaviFilter from '@lb/components/Filter/LingaBhairaviFilter';
import LingaBhairaviFilterBlog from '@lb/components/Filter/SearchFilterBlog';
import SectionTitle from '@lb/components/Headings/SectionTitle';
import LBhowToParticipateGroup from '@lb/components/LBhowToParticipateGroup';
import MidContainer from '@lb/components/Layout/MidContainer';
import ImportantMessage from '@lb/components/Message/ImportantMessage';
import LBContactUsCta from '@lb/components/Message/LBContactUsCta';
import EventWaysToCelebrate from '@lb/components/Sliders/EventWaysToCelebrate';
import PromotionalBannerSlider from '@lb/components/Sliders/PromotionalBannerSlider';
import RelatedLBSadhanaBringDeviHome from '@lb/components/Sliders/RelatedLBSadhanaBringDeviHome';
import RGallery from '@lb/components/Sliders/ritual/RGallery';
import SingleLineText from '@lb/components/Text/SingleLineText';
import StickyGroup from '@lb/components/UI/StickyGroup';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';
/* Styles */
import classes from '@lb/components/Utility/STstyle.module.css';

/* Services */

/**
 * Renders the Section UI Component
 *
 * @param {object} sectionContent - Content from API
 * @param {string} language - Language of page
 * @param {string} region - Region of Page
 * @param {boolean} isLeftMenu - Left Navbar
 * @param {string} pType
 * @param {string} pageName
 * @returns {ReactElement} UpcomingDates component
 */

const CommonComponents = ({
  sectionContent,
  region,
  language,
  section,
  isLeftMenu,
  pType,
  pageName,
  csrData,
  pageData,
  index,
}) => {
  const router = useRouter();
  return (
    <Box w="100%" display="flex" flexDirection="column" pb="0" alignItems={{ base: pageName == 'iso-bs' ? 'center' : 'unset', md: 'unset' }}>
      {RenderComponents({
        sectionContent,
        region,
        language,
        section,
        isLeftMenu,
        pType,
        pageName,
        csrData,
        router,
        pageData,
        index,
      })}
    </Box>
  );
};

const RenderComponents = ({
  sectionContent,
  region,
  language,
  section,
  pType,
  pageName,
  csrData,
  router,
  pageData,
  index,
}) => {
  switch (sectionContent._modelApiKey) {
    case 'top_banner_simple':
      return <TopBannerSimple sectionContent={sectionContent} />;
    case 'top_banner_event':
      return (
        <>
          <TopBannerEvent
            sectionContent={sectionContent}
            region={region}
            language={language}
            locations={pageData?.locations}
            pageName={pageName}
          />
          {/* <DynamicComp
            link="dd"
            sectionContent={sectionContent}
            region={region}
            language={language}
            locations={pageData?.locations}
          /> */}
        </>
      );
    case 'linga_bhairavi_promotional_banner':
      return (
        <Box>
          <PromotionalBanner
            sectionContent={sectionContent}
            region={region}
            language={language}
            pType={pType}
            section={section}
          />
        </Box>
      );
    case 'top_banner_with_autoplay_video':
      return (
        <TopBannerWithAutoplayVideo
          sectionContent={sectionContent}
          region={region}
          language={language}
          pType={pType}
          section={section}
          locations={pageData?.locations}
        />

      );
    case 'linga_bhairavi_promotional_banner_slider':
      return (
        <Box>
          <PromotionalBannerSlider
            sectionContent={sectionContent}
            region={region}
            language={language}
            pType={pType}
            section={section}
          />
        </Box>
      );
    case 'single_line_text':
      return (
        <Box>
          <SingleLineText sectionContent={sectionContent} />
        </Box>
      );
    case 'top_banner_linga_bhairavi_dark':
      return (
        <>
          <TopBannerDark
            sectionContent={sectionContent}
            region={region}
            language={language}
          />
        </>
      );
    case 'linga_bhairavi_lunar_calendar_ad':
      return (
        <LunarCalendar
          sectionContent={sectionContent}
          pType={pType}
          section={section}
        />
      );
    case 'structured_text':
      return (
        <MidContainer w="696px">
          <Box
            sx={{
              h2: {
                fontSize: '26px',
              },
              h3: {
                fontSize: '18px',
              },
            }}
            px={{ base: '15px', md: '0' }}
            pb={{ base: '40px', md: '40px' }}
          >
            <StructuredTextParser
              str={render(sectionContent.body)}
              region={region}
              sectionContent={sectionContent}
              lang={language}
              textAlign={sectionContent.style}
              className={
                sectionContent?.style === 'lb-spl-bullet'
                  ? classes.spl_bull_list
                  : classes.LBStructured
              }
            />
          </Box>
        </MidContainer>
      );
    case 'linga_bhairavi_filter':
      return (
        <LingaBhairaviFilter
          sectionContent={sectionContent}
          section={section}
          pageData={pageData}
        />
      );
    case 'section_title':
      return (
        <SectionTitle
          titleObj={sectionContent}
          maxW={{ base: '280px', sm: '350px', md: '663px' }}
          mx={{ base: '17px', md: 'auto' }}
          pageName={pageName}
        />
      );
    case 'linga_bhairavi_how_to_participate_group':
      return (
        <>
          <LBhowToParticipateGroup
            language={language}
            region={region}
            sectionContent={sectionContent}
          />
        </>
      );
    case 'linga_bhairavi_in_person_offering_group':
      return (
        <>
          <LBInPersonOfferingGroup
            language={language}
            region={region}
            sectionContent={sectionContent}
          />
        </>
      );

    case 'simple_button':
      return (
        <SimpleButton
          style={sectionContent}
          region={region}
          lang={language}
          mx="auto"
        />
      );
    case 'gallery':
      return (
        <Box>
          <RGallery sectionContent={sectionContent.images} />
        </Box>
      );
    case 'linga_bhairavi_card':
      return (
        <LingaBhairaviCard
          sectionContent={sectionContent}
          styleConfig={section.styleConfig}
          region={region}
          lang={language}
          pageData={pageData}
          index={index}
        />
      );
    case 'newsletter_subscription_form':
      const ArticlesSubscribeBox = dynamic(() =>
        import('@lb/components/Subscribe/ArticlesSubscribeBox')
      );
      return (
        <ArticlesSubscribeBox
          region={region}
          language={language}
          sectionContent={sectionContent}
        />
      );
    case 'related_linga_bhairavi_blog_content':
      return (
        <Box>
          <RelatedContentSlider
            region={region}
            lang={language}
            sectionContent={sectionContent?.posts}
            mainSection={sectionContent}
            background="none"
            pType={pType}
            style={'homepage-blog-list'}
          />
        </Box>
      );
    case 'linga_bhairavi_filter_blog':
      return (
        <LingaBhairaviFilterBlog
          sectionContent={sectionContent}
          section={section}
          region={region}
          lang={language}
        />
      );
    case 'linga_bhairavi_sticky_group':
      return (
        <>
          <StickyGroup
            sectionContent={sectionContent}
            region={region}
            lang={language}
          />
        </>
      );
    case 'linga_bhairavi_facade_blog_listing_placeholder':
      if (sectionContent.contentType === 'linga_bhairavi_blog_topic') {
        return (
          <LBBlogTopics
            sectionContent={sectionContent}
            region={region}
            language={language}
          />
        );
      } else {
        return (
          <LingaBhairaviFacadeBlogListingPlaceholder
            sectionContent={sectionContent}
            region={region}
            language={language}
          />
        );
      }
    case 'linga_bhairavi_blog_home_facade_placeholder':
      return (
        <LingaBhairaviBlogHomeFacadePlaceholder
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'single_image':
      return (
        <SingleImage
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'linga_bhairavi_volunteer_ad':
      return (
        <VolunteerAd
          sectionContent={sectionContent}
          region={region}
          lang={language}
          pType={pType}
          section={section}
        />
      );
    case 'card_zigzag_group':
      return (
        <>
          <Box py={{ base: '30px', md: '60px' }}>
            <CardZigzagGroup sectionContent={sectionContent} />
          </Box>
        </>
      );
    case 'card_info3_column_group':
      return (
        <>
          <CardInfo3ColumnGroup
            region={region}
            sectionContent={sectionContent}
          />
        </>
      );
    case 'important_message':
      return (
        <>
          <ImportantMessage sectionContent={sectionContent} />
        </>
      );
    case 'linga_bhairavi_contact_us_cta':
      return (
        <>
          <LBContactUsCta sectionContent={sectionContent} />
        </>
      );
    case 'quote':
      return (
        <>
          <Quote sectionContent={sectionContent} />
        </>
      );
    case 'media_embed':
      const MediaEmbed = dynamic(() =>
        import('@lb/components/Embeds/MediaEmbed')
      );
      return <MediaEmbed sectionContent={sectionContent} />;
    case 'media_embed_v2':
      const MediaEmbedV2 = dynamic(() =>
        import('@lb/components/Embeds/MediaEmbed_V2')
      );
      return <MediaEmbedV2 sectionContent={sectionContent} />;
    case 'share_widget':
      const SocialLinks = dynamic(() => import('@components/UI/SocialLinks'));
      return <SocialLinks url={router.asPath} />;
    case 'google_map':
      const GoogleMap = dynamic(() =>
        import('@lb/components/Embeds/GoogleMap')
      );
      return <GoogleMap sectionContent={sectionContent} />;
    case 'json_table':
      return (
        <JsonTable
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'linga_bhairavi_event_ways_to_celebrate':
      return (
        <EventWaysToCelebrate
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'related_linga_bhairavi_sadhana_bring_devi_home':
      return (
        <RelatedLBSadhanaBringDeviHome
          sectionContent={sectionContent}
          region={region}
          lang={language}
          pageData={pageData}
          pType={pType}
          section={section}
        />
      );
    case 'linga_bhairavi_offering_group':
      return (
        <OfferingGroup
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'generic_iframe':
      const GenericFrame = dynamic(() =>
        import('@components/Embeds/GenericFrame')
      );
      return <GenericFrame sectionContent={sectionContent} section={section} />;
    case 'faq_section_v2':
      return (
        <MidContainer w="770px">
          <FaqSectionV2
            faqSection={sectionContent}
            region={region}
            lang={language}
            pageName={pageName}
          />
        </MidContainer>
      );
    case 'visit_linga_bhairavi_card_group':
      return (
        <VisitLBCardGroup
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'linga_bhairavi_temple_address_timing':
      return (
        <LBTempleAddressTiming
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'faq_section_group_v2':
      return (
        <FaqSectionGroupV2
          faqSection={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'faq_expanded_section':
      return (
        <FaqExpandedSection
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
  }
};

export default CommonComponents;
